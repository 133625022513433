// -*- mode: RJSX; js-indent-level: 2; -*-

import { createSlice, createAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { logout } from './user';
import { deleteMarkup, markupUpdated } from './markup';

export const useTitleSuffix = () => useSelector((state) => {
  const n = state.ui.titleSuffixes?.length || 0;
  return n > 0 && state.ui.titleSuffixes[n-1];
});

export const useCardExpanded = (id) => useSelector((state) => state.ui.expandedCards && state.ui.expandedCards[id]);
export const useMarkupDefaults = (type) => useSelector((state) => type ? state.ui.defaults[type] : state.ui.defaults);
export const useFocused = () => useSelector((state) => state.ui.focused);
export const useFocusedMarkup = () => useSelector((state) => {
  if (state.ui.focused?.markup) {
    for (let d in state.markup) {
      const m = state.markup[d].lookup[state.ui.focused.markup];
      if (m) {
        return m;
      }
    }
  }
  return null;
});
export const emptyObject = {};
export const useAddingMarkup = () => useSelector((state) => state.ui.adding ?? emptyObject);
export const useInstructionsSnack = () => useSelector((state) => state.ui.instructionsSnack || '');
export const useFeedbackSnack = () => useSelector((state) => state.ui.feedbackSnack || emptyObject);
export const useHighlightedSubMarker = () => useSelector((state) => state.ui.highlightedSubMarker);

export const useImpersonating = () => useSelector((state) => state.ui.impersonating);
export const impersonateUser = createAction('ui/impersonate');

const defaultDefaults = {
  poi: {
    style: 'circleLarge',
    color: '#be0000',
    size: 25,
    folder: null,
  },
  line: {
    style: 'solid',
    color: '#be0000',
    size: 3,
    folder: null,
  },
  area: {
    style: 'filled',
    color: '#be0000',
    size: 3,
    folder: null,
  },
  'prop-fi': {
    style: 'filled',
    color: '#4d008000',
    size: 2,
    folder: null,
  },
  'prop-se': {
    style: 'filled',
    color: '#4d008000',
    size: 2,
    folder: null,
  },
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    defaults: defaultDefaults,
    focused: null,
  },
  reducers: {
    addTitleSuffix: (state, action) => {
      state.titleSuffixes = [...(state.titleSuffixes || []), action.payload];
    },
    removeTitleSuffix: (state, action) => {
      const i = (state.titleSuffixes || []).indexOf(action.payload);
      if (i >= 0) {
        state.titleSuffixes.splice(i, 1);
      }
    },
    requestFocus: {
      prepare: (markup, opts) => ({
        payload: {
          markup: (!markup || typeof(markup) === 'number') ? markup : markup.properties.id,
          ...(opts || {}),
        },
      }),
      reducer: (state, action) => ({
        ...state,
        focused: action.payload,
      }),
    },
    requestAdding: {
      prepare: (type, markup) => ({payload: {type, data: markup}}),
      reducer: (state, action) => ({
        ...state,
        adding: action.payload,
      }),
    },
    dismissAdding: {
      reducer: (state, action) => ({
        ...state,
        adding: {
          ...state.adding,
          type: null,
        },
      }),
    },
    editAdding: (state, action) => ({
      ...state,
      adding: {
        ...state.adding,
        data: {
          ...state.adding.data,
          properties: {
            ...state.adding.data.properties,
            ...action.payload,
          },
        },
      },
    }),
    setInstructionsSnack: {
      prepare: (text, id, opts) => ({payload: {text, id: id || null, ...(opts || {})}}),
      reducer: (state, action) => {
        const {text, id, ...opts} = action.payload;
        return {
          ...state,
          instructionsSnack: {
            text: text,
            setter: id,
            show: true,
            ...opts,
          },
        };
      },
    },
    dismissInstructionsSnack: (state, action) => ({
      ...state,
      instructionsSnack: action.payload && action.payload !== state.instructionsSnack.setter ?
        state.instructionsSnack :
        {
          ...state.instructionsSnack,
          show: false,
        },
    }),
    minimizeInstructionsSnack: (state, action) => ({
      ...state,
      instructionsSnack: action.payload && action.payload !== state.instructionsSnack.setter ?
        state.instructionsSnack :
        {
          ...state.instructionsSnack,
          minimized: true,
        },
    }),
    highlightSubMarker: {
      prepare: (markup, index) => {
        return ({payload: markup ? {markup, index} : null});
      },
      reducer: (state, action) => ({
        ...state,
        highlightedSubMarker: action.payload,
      }),
    },
    updateMarkupDefaults: {
      prepare: (type, props) => ({payload: {type, props}}),
      reducer: (state, action) => ({
        ...state,
        defaults: {
          ...state.defaults,
          [action.payload.type]: {
            ...(state.defaults[action.payload.type] ?? {}),
            ...action.payload.props,
          },
        },
      })
    },
    showFeedbackSnack: {
      prepare: (type, message) => ({payload: {type, message}}),
      reducer: (state, action) => ({
        ...state,
        feedbackSnack: {
          open: true,
          ...action.payload,
        },
      }),
    },
    dismissFeedbackSnack: (state, action) => ({
      ...state,
      feedbackSnack: {
        ...(state.feedbackSnack ?? {}),
        open: false,
      },
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, (state, action) => ({
        defaults: defaultDefaults,
      }))
      .addCase(deleteMarkup.pending, (state, action) => {
        return ({
        ...state,
        focused: action.meta.arg?.markup?.properties?.id === state.focused?.markup ? null : state.focused,
        });
      })
      .addCase(markupUpdated, (state, action) => (
        action.payload.markup.geometry ?
          state :
          {
            ...state,
            focused: action.payload.markup.properties.id === state.focused?.markup ? null : state.focused,
          }
      ))
      .addCase(impersonateUser, (state, action) => ({
        ...state,
        impersonating: action.payload,
      }))
      .addDefaultCase((state, action) => {});
  }
});

export const {
  addTitleSuffix,
  removeTitleSuffix,
  requestFocus,
  requestAdding,
  dismissAdding,
  editAdding,
  setInstructionsSnack,
  dismissInstructionsSnack,
  minimizeInstructionsSnack,
  highlightSubMarker,
  updateMarkupDefaults,
  showFeedbackSnack,
  dismissFeedbackSnack,
} = uiSlice.actions;
export default uiSlice.reducer;
