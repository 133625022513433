// -*- mode: RJSX; js-indent-level: 2; -*-

import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Typography, Fab } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import AddPoiIcon from '@mui/icons-material/AddLocation';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import DrawerTabPanel from './DrawerTabPanel';
import { useUndo } from './Undo';
import {
  useFocused,
  setInstructionsSnack,
  useMarkupDefaults,
  useAddingMarkup,
  requestAdding,
} from '../state/ui';
import { deleteMarkup, resurrectMarkup, nextZ } from '../state/markup';

const useStyles = makeStyles((theme) => ({
  instructions: {
    padding: '1em',
    paddingTop: 0,
    '&:first-child': {
      paddingTop: '1em',
    },
  },
  card: {
    marginBottom: '1em',
  },
}));

const AddMultiPoiIcon = () => (
  <svg className='MuiSvgIcon-root' focusable='false' viewBox='0 0 24 24' width='24' height='24' fill='#fff' aria-hidden={true}>
    <path d="M8.4773 3.0227c-3.86,0 -7,3.14 -7,7 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.86 -3.14,-7 -7,-7zm4 8l-3 0 0 3 -2 0 0 -3 -3 0 0 -2 3 0 0 -3 2 0 0 3 3 0 0 2z"/>
    <path d="M11.9773 2.0227c-0.7717,0 -1.5146,0.1258 -2.2094,0.3575 1.621,0.2728 3.0727,1.0502 4.1872,2.1647 0.9492,0.9492 1.6538,2.143 2.0105,3.4778l0.0117 0 0 0.044c0.163,0.625 0.25,1.2806 0.25,1.956 0,3.5821 -3.0036,8.1706 -5.1291,10.9694 0.535,0.6495 0.8791,1.0306 0.8791,1.0306 0,0 7,-7.75 7,-13 0,-3.86 -3.14,-7 -7,-7z"/>
    <path d="M15.4773 1.0227c-0.6359,0 -1.252,0.0857 -1.838,0.2453 1.4712,0.3226 2.7872,1.0642 3.8158,2.0928 1.4031,1.4031 2.2722,3.3408 2.2722,5.4778 0,3.623 -3.0728,8.276 -5.2018,11.065 0.5764,0.7033 0.9518,1.1191 0.9518,1.1191 0,0 7,-7.75 7,-13 0,-3.86 -3.14,-7 -7,-7z"/>
  </svg>
);

const PointMarkup = ({map, design, tracking, onDragEnd}) => {
  const classes = useStyles();
  const list = design.contents?.point ?? [];
  const dispatch = useDispatch();
  const focused = useFocused();
  const [multiSelect, setMultiSelect] = useState(null);
  const [setUndo] = useUndo();
  const adding = useAddingMarkup();
  const { t } = useTranslation();
  const poiDefaults = useMarkupDefaults('poi');

  useEffect(() => {
    if (multiSelect) {
      for (const k in multiSelect) {
        if (multiSelect[k]) {
          return;
        }
      }
      setMultiSelect(null);
    }
  }, [multiSelect]);

  const newPoi = () => ({
    type: 'Feature',
    geometry: {
      type: adding.type === 'poi-multi' ? 'MultiPoint' : 'Point',
    },
    properties: {
      ...poiDefaults,
      id: 'new',
      visible: true,
      burn: true,
      z: nextZ(list, 'point', false),
    }
  });

  return (
    <>
      <DrawerTabPanel
          className={classes.tabPanel}
          tracking={tracking}
          design={design}
          onDeactivate={() => setMultiSelect(null)}
          fab={[
            {
              key: 'add',
              position: 0,
              visible: !adding.type && !focused?.markup && !multiSelect,
              fab: (
                <Fab color='secondary' onClick={(ev) => {
                  dispatch(requestAdding('poi', newPoi()));
                  dispatch(setInstructionsSnack(t('point-new-snack'), 'point/new'));
                }}>
                  <AddPoiIcon/>
                </Fab>
              ),
            },
            {
              key: 'add-multi',
              position: 1,
              visible: !adding.type && !focused?.markup && !multiSelect,
              fab: (
                <Fab color='secondary' onClick={(ev) => {
                  dispatch(requestAdding('poi-multi', newPoi()));
                  dispatch(setInstructionsSnack(t('point-new-snack'), 'point/new'));
                }}>
                  <AddMultiPoiIcon/>
                </Fab>
              ),
            },
            {
              key: 'msdone',
              position: 0,
              visible: !!multiSelect,
              fab: (
                <Fab color='secondary' onClick={(ev) => {
                  setMultiSelect(null);
                }}>
                  <DoneIcon/>
                </Fab>
              ),
            },
            {
              key: 'trash',
              position: 1,
              visible: !!multiSelect,
              fab: (
                <Fab color='secondary' onClick={(ev) => {
                  const fwd = [];
                  const bwd = [];
                  let name;
                  for (const k in multiSelect) {
                    if (multiSelect[k]) {
                      const m = design.contents.lookup[k];
                      if (m && !m.properties.linkedTo) {
                        name = m.properties.name || `(${t('no-name')})`;
                        fwd.push(deleteMarkup(m, tracking));
                        bwd.push(resurrectMarkup(m, tracking));
                      }
                    }
                  }
                  if (fwd.length) {
                    setUndo(t('undo-delete-markup'), fwd, bwd, dispatch,
                            t('undo-hint-multi-deleted', {count: fwd.length, name}));
                  }
                  setMultiSelect(null);
                }}>
                  <DeleteIcon/>
                </Fab>
              ),
            },
          ]}
          instructions={(
            <>
              <Typography variant='body1' className={classes.instructions}>
                <Trans>point-instructions-1</Trans>
              </Typography>
              <Typography variant='body1' className={classes.instructions}>
                <Trans>point-instructions-2</Trans>
              </Typography>
              {
                !list.length &&
                  <Typography variant='body1' className={classes.instructions}>
                    <Trans>point-list-empty</Trans>
                  </Typography>
              }
            </>
          )}
          onDragEnd={onDragEnd}
          page='POINT'
          markupList={list}
          markupProps={{
            multiSelect: multiSelect,
            setMultiSelect: setMultiSelect,
            map: map,
          }} />
    </>
  );
};

export default PointMarkup;
