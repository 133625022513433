// -*- mode: RJSX; js-indent-level: 2; -*-

import { useMap } from 'react-leaflet';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import clsx from 'clsx';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import MapButton from './MapButton';

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    right: '1em',
    top: 0,
    bottom: 0,
  },
  align: {
    height: '100%',
  },
  spacer: {
    height: '1em',
  },
}));

const ZoomControl = () => {
  const classes = useStyles();
  const map = useMap();
  return (
    <div className={clsx(classes.container, 'leaflet-control')}>
      <Box display='flex' flexDirection='column' className={classes.align}>
        <Box flexGrow={1}/>
        <Box>
          <MapButton onClick={() => map.zoomIn()}>
            <ZoomInIcon/>
          </MapButton>
        </Box>
        <Box className={classes.spacer}/>
        <Box>
          <MapButton onClick={() => map.zoomOut()}>
            <ZoomOutIcon/>
          </MapButton>
        </Box>
        <Box flexGrow={1}/>
      </Box>
    </div>
  );
};

export default ZoomControl;
