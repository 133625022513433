// -*- mode: RJSX; js-indent-level: 2; -*-

import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Fab,
  Typography,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
  Switch,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import DrawerTabPanel from './DrawerTabPanel';
import { useUndo } from './Undo';
import { newMarkup, markupChanged, nextZ, fetchOmariistaMarkup, fetchPropFiEstate } from '../state/markup';
import {
  useFocused,
  useFocusedMarkup,
  requestFocus,
  setInstructionsSnack,
  dismissInstructionsSnack,
  useAddingMarkup,
  requestAdding,
  dismissAdding,
  useMarkupDefaults,
} from '../state/ui';
import { usePrevious } from '../util';
import { updateInteractive } from './MapEditor';
import { deleteMarkup, resurrectMarkup, editMarkup, useMarkup } from '../state/markup';

const useStyles = makeStyles((theme) => ({
  instructions: {
    padding: '1em',
    paddingTop: 0,
    '&:first-child': {
      paddingTop: '1em',
    },
  },
  card: {
    marginBottom: '1em',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

const BackgroundMarkup = ({design, map, tracking, onDragEnd}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [refPrompt, setRefPrompt] = useState(null);
  const [refCode, setRefCode] = useState({});
  const list = design.contents?.background ?? [];
  const focused = useFocused();
  const focusedMarkup = useFocusedMarkup();
  const adding = useAddingMarkup();
  const wasAdding = usePrevious(adding);
  const [multiSelect, setMultiSelect] = useState(null);
  const [setUndo] = useUndo();
  const propFiDefaults = useMarkupDefaults('prop-fi');
  const propSeDefaults = useMarkupDefaults('prop-se');
  const allMarkup = useMarkup(design.id);

  useEffect(() => {
    if (multiSelect) {
      for (const k in multiSelect) {
        if (multiSelect[k]) {
          return;
        }
      }
      setMultiSelect(null);
    }
  }, [multiSelect, focused, dispatch]);

  const addOmaRiista = () => {
    setMenuAnchor(null);
    setRefCode({code: '', option: true});
    setRefPrompt({
      title: 'oma-riista-fi',
      prompt: 'oma-riista-prompt',
      label: 'oma-riista-label',
      optionTitle: 'oma-riista-markers',
      handleFetch: async (code, option) => {
        setRefCode({code, option, fetching: true});
        const resp = await fetchOmariistaMarkup(
          design.id,
          code,
          nextZ(design.contents.background, 'background', false),
          option ? nextZ(design.contents.point, 'point', false) : null,
          tracking,
        );
        if (resp) {
          const all = [];
          if (option) {
            for (const i in resp.markup) {
              const m = {design: design.id, ...resp.markup[i]};
              all.push(m);
              dispatch(newMarkup(m));
            }
          } else {
            const m = {design: design.id, ...resp};
            dispatch(newMarkup(m));
            all.push(m);
          }
          dispatch(requestFocus(all[0], {flyTo: true, scrollTo: true}));
          setUndo(t('undo-new-markup', {name: all[0].properties.name || `(${t('no-name')})`}),
                  all.map((m) => resurrectMarkup(m, tracking)),
                  all.map((m) => deleteMarkup(m, tracking)));
          setRefPrompt(null);
        } else {
          setRefCode({code, option, failed: true, fetching: false});
        }
      },
    });
  };

  const addFinStateHunting = () => {
    setMenuAnchor(null);
    dispatch(requestAdding('state-hunting-fi'));
  };

  useEffect(() => {
    if (adding?.type === 'state-hunting-fi') {
      dispatch(setInstructionsSnack(t('state-hunting-fi-instructions'),
                                    'BackgroundMarkup/state-hunting-fi',
                                    {stopAddingOnClose: true}));
      return () => dispatch(dismissInstructionsSnack('BackgroundMarkup/state-hunting-fi'));
    }
    return undefined;
  }, [adding, dispatch, t]);

  const addFinPropBorders = () => {
    setMenuAnchor(null);
    dispatch(requestAdding('prop-fi', {
      type: 'FinProperty',
      geometry: {
        type: 'MultiPolygon',
      },
      properties: {
        ...propFiDefaults,
        id: 'new',
        visible: true,
        burn: true,
        z: nextZ(list, 'background', false),
      }
    }));
  };

  const addSwePropBorders = () => {
    setMenuAnchor(null);
    dispatch(requestAdding('prop-se', {
      type: 'SweProperty',
      geometry: {
        type: 'MultiPolygon',
      },
      properties: {
        ...propSeDefaults,
        id: 'new',
        visible: true,
        burn: true,
        z: nextZ(list, 'background', false),
      }
    }));
  };

  useEffect(() => {
    if (adding?.type === 'prop-fi' || adding?.type === 'prop-fi-code') {
      dispatch(setInstructionsSnack(t('prop-fi-instructions'), 'BackgroundMarkup/prop-fi', {
        setAddingButton: {
          adding: requestAdding('prop-fi-code', adding?.data),
          title: t('prop-fi-search'),
        },
      }));
      return () => dispatch(dismissInstructionsSnack('BackgroundMarkup/prop-fi'));
    } else if (adding?.type === 'prop-se') {
      dispatch(setInstructionsSnack(t('prop-fi-instructions'), 'BackgroundMarkup/prop-se'));
      return () => dispatch(dismissInstructionsSnack('BackgroundMarkup/prop-se'));
    }
    return undefined;
  }, [adding, dispatch, t]);

  useEffect(() => {
    if (adding?.type !== wasAdding?.type && adding?.type === 'prop-fi-code') {
      setRefCode({code: ''});
      setRefPrompt({
        title: 'prop-border-fi',
        prompt: 'prop-fi-prompt',
        label: 'prop-fi-label',
        handleFetch: async (code) => {
          setRefCode({code, fetching: true});
          const resp = await fetchPropFiEstate(design.id, focused?.markup ?? adding.data, code, tracking);
          if (resp?.markup) {
            const m = {design: design.id, ...resp.markup};
            if (focused?.markup === resp.markup.properties.id) {
              // Added to current markup; refresh geometry
              dispatch(markupChanged(m));
              map.flyToBounds([[resp.bbox[1], resp.bbox[0]], [resp.bbox[3], resp.bbox[2]]]);
              setUndo(t('undo-prop-add', {name: m.properties.name || `(${t('no-name')})`}),
                      editMarkup({design: design.id, id: focused.markup, geometry: m.geometry, tracking}),
                      editMarkup({design: design.id, id: focused.markup, geometry: focusedMarkup.geometry, tracking}));
            } else {
              // New markup
              dispatch(newMarkup(m));
              dispatch(requestFocus(m, {edit: true, flyTo: true, scrollTo: true}));
              dispatch(dismissAdding());
              setUndo(t('undo-new-markup', {name: m.properties.name || `(${t('no-name')})`}),
                      resurrectMarkup(m, tracking), deleteMarkup(m, tracking));
            }
            setRefPrompt(null);
            updateInteractive(false);
          } else {
            setRefCode({code, failed: true, fetching: false});
          }
        },
      });
    }
  }, [adding, wasAdding, design, map, focused, dispatch, focusedMarkup, setUndo, tracking, t]);

  useEffect(() => {
    if (!refPrompt && adding?.type === 'prop-fi-code') {
      dispatch(requestAdding('prop-fi', adding.data));
    }
  }, [refPrompt, adding, dispatch]);

  return (
    <>
      <DrawerTabPanel
          className={classes.tabPanel}
          tracking={tracking}
          design={design}
          onDeactivate={() => setMultiSelect(null)}
          fab={[
            {
              key: 'add',
              position: 0,
              visible: !adding?.type && !focused?.edit && !multiSelect,
              fab: (
                <Fab color='secondary' onClick={(ev) => setMenuAnchor(ev.currentTarget)}>
                  <AddIcon/>
                </Fab>
              ),
            },
            {
              key: 'msdone',
              position: 0,
              visible: !!multiSelect,
              fab: (
                <Fab color='secondary' onClick={(ev) => {
                  setMultiSelect(null);
                }}>
                  <DoneIcon/>
                </Fab>
              ),
            },
            {
              key: 'trash',
              position: 1,
              visible: !!multiSelect,
              fab: (
                <Fab color='secondary' onClick={(ev) => {
                  const fwd = [];
                  const bwd = [];
                  let name;
                  for (const k in multiSelect) {
                    if (multiSelect[k]) {
                      const m = design.contents.lookup[k];
                      if (m && !m.properties.linkedTo) {
                        name = m.properties.name || `(${t('no-name')})`;
                        fwd.push(deleteMarkup(m, tracking));
                        bwd.push(resurrectMarkup(m, tracking));
                        for (const id in allMarkup.lookup) {
                          const mm = allMarkup.lookup[id];
                          if (mm.properties?.linkedTo === m.properties.id) {
                            fwd.push(deleteMarkup(mm, tracking));
                            bwd.push(resurrectMarkup(mm, tracking));
                          }
                        }
                      }
                    }
                  }
                  if (fwd.length) {
                    setUndo(t('undo-delete-markup'), fwd, bwd, dispatch,
                            t('undo-hint-multi-deleted', {count: fwd.length, name}));
                  }
                  setMultiSelect(null);
                }}>
                  <DeleteIcon/>
                </Fab>
              ),
            },
          ]}
          instructions={(
            <>
              <Typography variant='body1' className={classes.instructions}>
                <Trans>background-instructions-1</Trans>
              </Typography>
              {
                !list.length &&
                  <Typography variant='body1' className={classes.instructions}>
                    <Trans>background-list-empty</Trans>
                  </Typography>
              }
            </>
          )}
          onDragEnd={onDragEnd}
          page='BACKGROUND'
          markupList={list}
          markupProps={{
            multiSelect: multiSelect,
            setMultiSelect: setMultiSelect,
            map: map,
          }}>
      </DrawerTabPanel>
      <Menu anchorEl={menuAnchor}
            open={!!menuAnchor}
            onClose={() => setMenuAnchor(null)}>
        <MenuItem onClick={() => addOmaRiista()}><Trans>oma-riista-fi</Trans></MenuItem>
        <MenuItem onClick={() => addFinStateHunting()}><Trans>state-hunting-fi</Trans></MenuItem>
        <MenuItem onClick={() => addFinPropBorders()}><Trans>prop-border-fi</Trans></MenuItem>
        <MenuItem onClick={() => addSwePropBorders()}><Trans>prop-border-se</Trans></MenuItem>
      </Menu>
      <Dialog
          disableBackdropClick={!!refCode.Fetching}
          open={!!refPrompt}
          onClose={() => setRefPrompt(null)}>
        <DialogTitle>
          <Box display='flex' flexDirection='row'>
            {t(refPrompt?.title)}
            <Box flexGrow={1}/>
            {refCode.fetching && <CircularProgress size={32}/>}
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t(refPrompt?.prompt)}
          </DialogContentText>
          <TextField
              autoFocus
              fullWidth
              margin='dense'
              label={t(refPrompt?.label)}
              error={!!refCode.failed}
              helperText={refCode.failed ? t('code-wrong') : null}
              type='text'
              disabled={!!refCode.fetching}
              value={refCode.code}
              onChange={(ev) => setRefCode({...refCode, code: ev.target.value})}
              onKeyDown={(ev) => {
                if (ev.keyCode === 13) {
                  refPrompt?.handleFetch(refCode.code, refCode.option);
                }
              }}/>
          {
            refPrompt?.optionTitle &&
            <Box className={classes.row}>
              <Box alignSelf='center' flexGrow={1}>
                <Typography variant='body1'>
                  {t(refPrompt.optionTitle)}
                </Typography>
              </Box>
              <Box alignSelf='center'>
                <Switch
                  color='primary'
                  checked={refCode.option ?? false}
                  onChange={() => setRefCode({...refCode, option: !refCode.option})} />
              </Box>
            </Box>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRefPrompt(null)} color="primary" disabled={!!refCode.fetching}>
            <Trans>cancel</Trans>
          </Button>
          <Button
            onClick={() => refPrompt?.handleFetch(refCode.code, refCode.option)}
            color="primary"
            disabled={refCode.fetching || !refCode.code}>
            <Trans>ok</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BackgroundMarkup;
