// -*- mode: RJSX; js-indent-level: 2; -*-

import { useEffect, useRef, useState, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useMap, LayerGroup } from 'react-leaflet';
import polygonClipping from 'polygon-clipping';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import MapMarkupLayer from './MapMarkupLayer';
import { useUndo } from './Undo';
import { polygonOptions, polylineOptions, typeForGeometryType, MapPatternContext } from '../icons/TargetStyles';
import {
  createNewMarkup,
  markupChanged,
  newMarkupPending,
  newMarkup,
  useWorking,
  nextZ,
  editMarkup,
  deleteMarkup,
  resurrectMarkup,
  fetchPropFiParcel,
  fetchPropSeParcel,
  fetchStateHuntingFiRegion,
  canEditMarkup,
} from '../state/markup';
import {
  useFocusedMarkup,
  requestFocus,
  useMarkupDefaults,
  showFeedbackSnack,
  useAddingMarkup,
  dismissAdding,
} from '../state/ui';
import {
  useMapLayers,
  useSelectedLayers,
  setActiveOverlays,
 } from '../state/layers';
import { polygonArea, pointInPolygon } from '../util';

export const mapEditOptions = {
  lineGuideOptions: {
    color: '#b2ff59',
    weight: 4,
  },
};

export const updateInteractive = (interactive) => {
  const all = document.querySelectorAll('.leaflet-interactive');
  all.forEach((e) => {
    const c = e.attributes.getNamedItem('class');
    const bare = c.value.split(' ').filter((n) => n !== 'disabled');
    if (!interactive) {
      bare.push('disabled');
    }
    c.value = bare.join(' ');
  });
};

const ensureLayerVisibility = (uuid, all, selected) => {
  uuid = uuid.toLowerCase();

  const search = (tree) => {
    for (let i = 0; i < tree.length; i++) {
      if (tree[i].id.toLowerCase() === uuid) {
        return tree[i];
      }
      if (tree[i].flavors) {
        const child = search(tree[i].flavors);
        if (child) {
          return child;
        }
      }
    }
    return null;
  };
  let wanted = search(all.overlay_maps);
  if (!wanted) {
    return null;
  }

  const anyChildActive = (spec) =>
        selected.overlayIds.indexOf(spec.id) >= 0 ||
        !!(spec.flavors || []).find((f) => anyChildActive(f));
  if (anyChildActive(wanted)) {
    return null;
  }

  while (!wanted.url && wanted.flavors?.length) {
    wanted = wanted.flavors[0];
  }
  if (!wanted.url) {
    return null;
  }

  return wanted.id;
};

const MapEditor = ({design, tracking}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const map = useMap();
  const group = useRef();
  const focused = useFocusedMarkup();
  const adding = useAddingMarkup();
  const working = useWorking(design?.id);
  const drawLayer = useRef(null);
  const interactive = !adding.type && !focused;
  const [fetching, setFetching] = useState(0);
  const [changeToMulti, setChangeToMulti] = useState({});
  const [setUndo] = useUndo();
  const [autoPoint, setAutoPoint] = useState(null);
  const patterns = useContext(MapPatternContext);
  const lineDefaults = useMarkupDefaults('line');
  const areaDefaults = useMarkupDefaults('area');
  const [mapLayers] = useMapLayers(t('lang-id'));
  const selectedLayers = useSelectedLayers();
  const [temporaryLayers, setTemporaryLayers] = useState({});

  useEffect(() => {
    map.on('editable:drawing:commit', async (ev) => {
      drawLayer.current = null;
      const json = ev.layer.toGeoJSON();
      if (adding?.type) {
        const {id, ...props} = adding.data.properties;
        json.properties = props;
        const atype = adding.type;
        switch (atype) {
        case 'line':
          break;
        case 'area':
          // Make the polygon canonical (linear ring(s)).
          // polygonClipping always returns a MultiPolygon.
          json.geometry = {
            type: 'MultiPolygon',
            coordinates: polygonClipping.union(json.geometry.coordinates),
          };
          if (!json.geometry.coordinates.length) {
            // Empty polygon
            console.warn('Empty polygon!');
            map.removeLayer(ev.layer);
            return;
          }
          break;
        default:
          console.warn('Unknown type', adding.type, 'for added feature', json);
          map.removeLayer(ev.layer);
          return;
        }

        ev.layer.disableEdit();
        dispatch(newMarkupPending(design.id, true));
        const resp = await createNewMarkup(design.id, json, tracking);
        if (resp?.properties) {
          map.removeLayer(ev.layer);
          const nm = {design: design.id, ...resp};
          dispatch(newMarkup(nm));
          dispatch(requestFocus(resp, {edit: true, scrollTo: true}));
          dispatch(dismissAdding());
          setUndo(t('undo-new-markup', {name: t('type-' + atype)}),
                  resurrectMarkup(nm, tracking),
                  deleteMarkup(nm, tracking));
        } else {
          dispatch(newMarkupPending(design.id, false));
        }
        updateInteractive(false);
      } else if (focused) {
        let geo = focused.geometry;
        switch (typeForGeometryType(focused)) {
        case 'line':
          if (geo.type === 'LineString') {
            geo = {
              type: 'MultiLineString',
              coordinates: [geo.coordinates, json.geometry.coordinates],
            };
          } else {
            geo = {
              ...geo,
              coordinates: [...geo.coordinates, json.geometry.coordinates],
            };
          }
          break;
        case 'area':
          let drawn = polygonClipping.union(json.geometry.coordinates);
          if (!drawn.length) {
            // Empty polygon
            console.warn('Empty polygon!');
            map.removeLayer(ev.layer);
            return;
          }
          // Check overlap with the existing polygon
          const common = polygonClipping.intersection(geo.coordinates, drawn);
          if (polygonArea(common) > polygonArea(drawn) / 2) {
            // More intersecting area than outside area; assume hole
            drawn = polygonClipping.difference(geo.coordinates, drawn);
          } else {
            // More outside area than intersecting area; assume union
            drawn = polygonClipping.union(geo.coordinates, drawn);
          }
          geo = {
            type: 'MultiPolygon',
            coordinates: drawn,
          };
          break;
        default:
          console.warn('Unknown type', geo?.type, 'for augmented feature', json, focused);
          map.removeLayer(ev.layer);
          return;
        }
        const fwd = {markup: focused, geometry: geo, tracking};
        const bwd = {markup: focused, geometry: focused.geometry, tracking};
        if (!focused.properties.visible) {
          fwd.visible = true;
          bwd.visible = false;
        }
        setUndo(t('undo-markup-geometry'), editMarkup(fwd), editMarkup(bwd), dispatch);
        map.removeLayer(ev.layer);
      } else {
        console.warn('Reason for editing disappeared!');
        map.removeLayer(ev.layer);
      }
    });
    map.on('editable:drawing:start', (ev) => {
      drawLayer.current = ev.layer;
    });
    map.on('editable:drawing:end', (ev) => {
      drawLayer.current = null;
    });
    return () => {
      map.off('editable:drawing:commit');
      map.off('editable:drawing:start');
      map.off('editable:drawing:end');
    };
  }, [
    map,
    design,
    dispatch,
    adding,
    focused,
    setUndo,
    tracking,
    t,
  ]);

  const type = adding?.type || (focused && canEditMarkup(focused) && typeForGeometryType(focused));
  const addingMarker = type === 'poi' || type === 'poi-multi';
  const addingPropFi = type === 'prop-fi';
  const addingStateHuntingFi = type === 'state-hunting-fi';
  const addingPropSe = type === 'prop-se';

  useEffect(() => {
    if (addingPropFi && !temporaryLayers.propFi) {
      const temp = ensureLayerVisibility('fin-property', mapLayers, selectedLayers);
      if (temp) {
        dispatch(setActiveOverlays({
          add: temp,
        }));
        setTemporaryLayers({
          ...temporaryLayers,
          propFi: temp,
        });
      } else {
        setTemporaryLayers({
          ...temporaryLayers,
          propFi: -1,
        });
      }
    } else if (!addingPropFi && temporaryLayers.propFi) {
      const {propFi, ...rest} = temporaryLayers;
      if (propFi) {
        if (propFi !== -1) {
          dispatch(setActiveOverlays({
            remove: propFi,
          }));
        }
        setTemporaryLayers(rest);
      }
    }
  }, [addingPropFi, temporaryLayers, mapLayers, selectedLayers, dispatch]);

  useEffect(() => {
    if (addingPropSe && !temporaryLayers.propSe) {
      const temp = ensureLayerVisibility('swe-property', mapLayers, selectedLayers);
      if (temp) {
        dispatch(setActiveOverlays({
          add: temp,
        }));
        setTemporaryLayers({
          ...temporaryLayers,
          propSe: temp,
        });
      } else {
        setTemporaryLayers({
          ...temporaryLayers,
          propSe: -1,
        });
      }
    } else if (!addingPropSe && temporaryLayers.propSe) {
      const {propSe, ...rest} = temporaryLayers;
      if (propSe) {
        if (propSe !== -1) {
          dispatch(setActiveOverlays({
            remove: propSe,
          }));
        }
        setTemporaryLayers(rest);
      }
    }
  }, [addingPropSe, temporaryLayers, mapLayers, selectedLayers, dispatch]);

  useEffect(() => {
    if (addingStateHuntingFi && !temporaryLayers.stateHuntingFi) {
      const temp = ensureLayerVisibility('fin-state-hunting', mapLayers, selectedLayers);
      if (temp) {
        dispatch(setActiveOverlays({
          add: temp,
        }));
        setTemporaryLayers({
          ...temporaryLayers,
          stateHuntingFi: temp,
        });
      } else {
        setTemporaryLayers({
          ...temporaryLayers,
          stateHuntingFi: -1,
        });
      }
    } else if (!addingStateHuntingFi && temporaryLayers.stateHuntingFi) {
      const {stateHuntingFi, ...rest} = temporaryLayers;
      if (stateHuntingFi) {
        if (stateHuntingFi !== -1) {
          dispatch(setActiveOverlays({
            remove: stateHuntingFi,
          }));
        }
        setTemporaryLayers(rest);
      }
    }
  }, [addingStateHuntingFi, temporaryLayers, mapLayers, selectedLayers, dispatch]);

  useEffect(() => {
    if (working) {
      return undefined;
    }
    switch (type) {
    case 'line':
      map.editTools.startPolyline(null, polylineOptions(focused?.properties || lineDefaults, null));
      break;
    case 'area':
      map.editTools.startPolygon(null, polygonOptions(focused?.properties || areaDefaults, null, map, patterns));
      break;
    default:
      break;
    }
    return () => {
      if (drawLayer.current) {
        map.removeLayer(drawLayer.current);
        drawLayer.current = null;
      }
      map.editTools.stopDrawing();
    };
  }, [type, map, working, drawLayer, lineDefaults, areaDefaults, patterns, focused?.properties]);

  useEffect(() => {
    updateInteractive(interactive);
  }, [interactive]);

  useEffect(() => {
    const c = map.getContainer().attributes.getNamedItem('class');
    const classes = c.value.split(' ').filter((n) => n !== 'crosshair-cursor' && n !== 'wait-cursor');
    if (fetching > 0 || working) {
      classes.push('wait-cursor');
    } else if (addingMarker || addingPropFi || addingPropSe || addingStateHuntingFi) {
      classes.push('crosshair-cursor');
    }
    c.value = classes.join(' ');
  }, [map, addingMarker, addingPropFi, addingPropSe, addingStateHuntingFi, fetching, working]);

  useEffect(() => {
    if (!adding.type && !focused) {
      setAutoPoint(null);
    }
  }, [adding, focused]);

  const addAsNew = useCallback(async (pt) => {
    let json;
    if (adding.type) {
      const {id, ...props} = adding.data.properties;
      json = {
        type: 'Feature',
        geometry: adding.type === 'poi-multi' ? {
          type: 'MultiPoint',
          coordinates: [pt],
        } : {
          type: 'Point',
          coordinates: pt,
        },
        properties: props,
      };
    } else if (focused) {
      const {id, name, ...props} = focused.properties;
      json = {
        ...focused,
        geometry: {
          ...focused.geometry,
          coordinates: focused.geometry.type === 'MultiPoint' ? [pt] : pt,
        },
        properties: {
          ...props,
          z: nextZ(design.contents.point, 'point', false),
        },
      };
    }
    if (!json) {
      return;
    }
    dispatch(newMarkupPending(design.id, true));
    const resp = await createNewMarkup(design.id, json, tracking);
    if (resp?.properties) {
      const nm = {design: design.id, ...resp};
      dispatch(newMarkup(nm));
      dispatch(requestFocus(resp, {edit: true, focusName: true, scrollTo: true}));
      dispatch(dismissAdding());
      if (nm.geometry.type === 'Point') {
        setAutoPoint('single');
      }
      setUndo(t('undo-new-markup', {name: t('type-point')}),
              resurrectMarkup(nm, tracking),
              deleteMarkup(nm, tracking));
    } else {
      dispatch(newMarkupPending(design.id, false));
    }
    updateInteractive(false);
  }, [design, dispatch, focused, adding, setUndo, tracking, t]);

  const addWhenFocused = useCallback((pt) => {
    let geo = focused.geometry;
    if (geo.type === 'Point') {
      geo = {
        type: 'MultiPoint',
        coordinates: [geo.coordinates, pt],
      };
    } else {
      geo = {
        ...geo,
        coordinates: [...geo.coordinates, pt],
      };
    }
    setUndo(t('undo-markup-geometry'),
            editMarkup({markup: focused, geometry: geo, tracking}),
            editMarkup({markup: focused, geometry: focused.geometry, tracking}),
            dispatch);
  }, [focused, dispatch, setUndo, tracking, t]);

  useEffect(() => {
    if (!addingMarker) {
      return undefined;
    }
    const click = async (ev) => {
      const pt = [ev.latlng.lng, ev.latlng.lat];
      if (adding?.type === 'poi' || adding?.type === 'poi-multi') {
        // New POI markup
        await addAsNew(pt);
      } else if (focused && typeForGeometryType(focused) === 'poi') {
        // Additional POI
        if (focused.geometry.type === 'Point') {
          if (autoPoint === 'single') {
            addAsNew(pt);
          } else if (autoPoint === 'multi') {
            addWhenFocused(pt);
          } else {
            setChangeToMulti({
              ask: true,
              location: pt,
            });
          }
        } else {
          addWhenFocused(pt);
        }
      }
    };
    map.on('click', click);
    return () => map.off('click', click);
  }, [addingMarker, adding, focused, map, addAsNew, addWhenFocused, autoPoint]);

  useEffect(() => {
    if (!addingPropFi && !addingPropSe) {
      return undefined;
    }
    const click = async (ev) => {
      const pt = [ev.latlng.lng, ev.latlng.lat];
      let handled = false;
      const propfoc = (focused?.type === 'FinProperty' || focused?.type === 'SweProperty') && focused;
      if (propfoc) {
        for (let i = 0; i < propfoc.geometry.coordinates.length; i++) {
          if (pointInPolygon(pt, propfoc.geometry.coordinates[i])) {
            // Inside an existing polygon, remove it
            if (propfoc.geometry.coordinates.length < 2) {
              // Out of geometry, delete the whole markup
              setUndo(t('undo-delete-markup'),
                      deleteMarkup(propfoc, tracking),
                      resurrectMarkup(propfoc, tracking),
                      dispatch);
            } else {
              const ng = {
                ...propfoc.geometry,
                coordinates: [
                  ...propfoc.geometry.coordinates.slice(0, i),
                  ...propfoc.geometry.coordinates.slice(i+1),
                ],
              };
              setUndo(t('undo-markup-geometry'),
                      editMarkup({markup: propfoc, geometry: ng, tracking}),
                      editMarkup({markup: propfoc, geometry: propfoc.geometry, tracking}),
                      dispatch);
            }
            handled = true;
            break;
          }
        }
      }
      if (!handled) {
        // On the map, add the parcel at cursor
        setFetching((f) => f + 1);
        const resp = await (
          addingPropFi ?
            fetchPropFiParcel(design.id, propfoc?.properties?.id ?? adding.data, pt, tracking) :
            fetchPropSeParcel(design.id, propfoc?.properties?.id ?? adding.data, pt, tracking));
        if (resp?.markup) {
          const m = {design: design.id, ...resp.markup};
          if (propfoc?.properties?.id === resp.markup.properties.id) {
            // Added to current markup; refresh geometry
            dispatch(markupChanged(m));
            setUndo(t('undo-markup-geometry'),
                    editMarkup({markup: propfoc, geometry: m.geometry, tracking}),
                    editMarkup({markup: propfoc, geometry: propfoc.geometry, tracking}));
          } else {
            // New markup
            dispatch(newMarkup(m));
            dispatch(requestFocus(m, {edit: true, scrollTo: true}));
            dispatch(dismissAdding());
            setUndo(t('undo-new-markup', {name: m.properties.name || `(${t('no-name')})`}),
                    resurrectMarkup(m, tracking), deleteMarkup(m, tracking));
          }
          updateInteractive(false);
        } else {
          dispatch(showFeedbackSnack('error', t('prop-fi-click-fail')));
        }
        setFetching((f) => f - 1);
      }
    };
    map.on('click', click);
    return () => map.off('click', click);
  }, [addingPropFi, addingPropSe, map, design, dispatch, focused, setUndo, adding, tracking, t]);

  useEffect(() => {
    if (!addingStateHuntingFi) {
      return undefined;
    }
    const click = async (ev) => {
      const pt = [ev.latlng.lng, ev.latlng.lat];
      // On the map, add the region(s) at cursor
      setFetching((f) => f + 1);
      const resp = await fetchStateHuntingFiRegion(
        design.id, pt, nextZ(design.contents.background, 'background', false), tracking);
      if (resp?.markup?.length) {
        const bbox = [[90, 180], [-90, -180]];
        const fwd = [];
        const bwd = [];
        let name;
        resp?.markup.forEach((rm) => {
          const m = {design: design.id, ...rm};
          dispatch(newMarkup(m));
          if (resp.markup.length === 1) {
            dispatch(requestFocus(m, {scrollTo: true, flyTo: true}));
          }
          if (m.bbox[0] < bbox[0][1]) bbox[0][1] = m.bbox[0];
          if (m.bbox[1] < bbox[0][0]) bbox[0][0] = m.bbox[1];
          if (m.bbox[2] > bbox[1][1]) bbox[1][1] = m.bbox[2];
          if (m.bbox[3] > bbox[1][0]) bbox[1][0] = m.bbox[3];
          fwd.push(resurrectMarkup(m, tracking));
          bwd.push(deleteMarkup(m, tracking));
          name = m.properties.name;
        });
        setUndo(t('undo-new-markup', {name: fwd.length > 1 ? t('marker-count', {count: fwd.length}) : name}), fwd, bwd);
        if (resp.markup.length > 1) {
          map.flyToBounds(bbox);
          dispatch(showFeedbackSnack('success', t('state-hunting-fi-multiple')));
        }
        dispatch(dismissAdding());
        updateInteractive(false);
      } else {
        dispatch(showFeedbackSnack('error', t('state-hunting-fi-click-fail')));
      }
      setFetching((f) => f - 1);
    };
    map.on('click', click);
    return () => map.off('click', click);
  }, [addingStateHuntingFi, map, design, dispatch, setUndo, tracking, t]);

  if (!design?.contents) {
    return null;
  }

  let z = 995;
  const renderMarkup = (m) => {
    switch (m.type) {
    case 'Feature':
    case 'OmaRiista':
    case 'FinStateHunting':
    case 'FinProperty':
    case 'SweProperty':
      const myz = z;
      if (z > 1) {
        z--;
      }
      return [
        <MapMarkupLayer
            markup={m}
            zIndex={myz}
            captureClick
            adding={!!adding?.type}
            tracking={tracking} />
      ];
    case 'Folder':
      return (m.children ?? []).flatMap(renderMarkup);
    default:
      return [];
    }
  };

  return (
    <>
      <LayerGroup ref={group}>
        {design.contents.point.flatMap(renderMarkup)}
        {design.contents.area.flatMap(renderMarkup)}
        {design.contents.background.flatMap(renderMarkup)}
      </LayerGroup>
      <Dialog open={!!changeToMulti.ask} onClose={() => setChangeToMulti({...changeToMulti, ask: false})}>
        <DialogTitle>
          <Trans>point-to-multi-title</Trans>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Trans>point-to-multi-prompt</Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            addAsNew(changeToMulti.location);
            setChangeToMulti({...changeToMulti, ask: false});
            setAutoPoint('single');
          }}>
            <Trans>new-marker</Trans>
          </Button>
          <Button onClick={() => {
            addWhenFocused(changeToMulti.location);
            setChangeToMulti({...changeToMulti, ask: false});
            setAutoPoint(null);
          }}>
            <Trans>combined-marker</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MapEditor;
