// -*- mode: RJSX; js-indent-level: 2; -*-

import { useEffect, useRef, useState, useContext } from 'react';
import { useMap, Pane, Polygon, Polyline, Marker } from 'react-leaflet';
import { useDispatch } from 'react-redux';
import L from 'leaflet';
import { useTranslation } from 'react-i18next';
import polygonClipping from 'polygon-clipping';
import { useUndo } from './Undo';
import { usePrevious } from '../util';
import { markerIcon, polygonOptions, polylineOptions, arrowizePolylines, MapPatternContext } from '../icons/TargetStyles';
import { canEditMarkup, freeEditMarkup, editMarkup, deleteMarkup, resurrectMarkup, markerLabel } from '../state/markup';
import {
  useFocused,
  requestFocus,
  setInstructionsSnack,
  dismissInstructionsSnack,
  highlightSubMarker,
  useHighlightedSubMarker,
  requestAdding,
} from '../state/ui';

const useCommitHandler = (markup, tracking) => {
  const ref = useRef({
    timer: null,
    changed: null,
  });
  const dispatch = useDispatch();
  const [setUndo, clearUndo] = useUndo();
  const { t } = useTranslation();
  return {
    _ref: ref.current,
    _killTimer: function() {
      if (this._ref.timer) {
        clearTimeout(this._ref.timer);
        this._ref.timer = null;
      }
    },
    edit: function(layer) {
      this._killTimer();
      this._ref.changed = layer;
      this._ref.timer = setTimeout(() => this.commit(), 5000);
      clearUndo();
    },
    discard: function() {
      this._killTimer();
      this._ref.changed = null;
    },
    commit: function() {
      this._killTimer();
      if (this._ref.changed) {
        setUndo(t('undo-markup-geometry'),
                editMarkup({markup, tracking, geometry: this._ref.changed.toGeoJSON().geometry}),
                editMarkup({markup, tracking, geometry: markup.geometry}),
                dispatch);
        this._ref.changed = null;
      }
    },
  };
};

// NOTE:
// Leaflet.editable with React is a bit of a fragile construct. The code below
// (MapPolygonLayer, MapPolylineLayer) has been carefully tuned so that everyting
// seems to work properly. After editing the useEffect() hooks or other details
// in the code, be sure to test that editing of lines/polygons still works properly
// (edit handles really control vertices, changes are when zooming around, etc.)

const MapPolygonLayer = ({forwardedRef, markup, onClick, interactive, editing, zIndex, tracking}) => {
  const map = useMap();
  const ref = useRef();
  const [z, setZ] = useState(map.getZoom());
  const [coords, setCoords] = useState(null);
  const patterns = useContext(MapPatternContext);
  const [forceRedraw, setForceRedraw] = useState(false);
  const haveEdited = useRef(false);

  const commitHandler = useCommitHandler(markup, tracking);

  useEffect(() => {
    const zoom = (ev) => {
      setZ(ev.target.getZoom());
    };
    map.on('zoomend', zoom);
    return () => map.off('zoomend', zoom);
  }, [map]);

  const prevZ = usePrevious(z);
  useEffect(() => {
    if (prevZ !== z || (!editing && commitHandler?._ref?.changed)) {
      commitHandler?.commit();
    }
  }, [prevZ, z, editing, commitHandler]);

  useEffect(() => {
    const mainStyle = markup.properties.style.split('/')[0];
    const union = !editing && !commitHandler?._ref?.changed && (markup.type === 'FinProperty' || markup.type === 'SweProperty');
    const inverted = !editing && !commitHandler?._ref?.changed && (mainStyle === 'inverted' || mainStyle === 'onlyInverted');
    let c = markup.geometry.coordinates;
    if (markup.geometry.type === 'Polygon') {
      c = [c];
    }
    if (union) {
      c = polygonClipping.union(c);
    }
    if (inverted) {
      c = polygonClipping.xor([[[-180,-90],[-180,90],[180,90],[180,-90],[-180,-90]]], c);
    }
    setCoords(L.GeoJSON.coordsToLatLngs(c, 2));
  }, [markup.geometry, markup.type, markup.properties, editing, commitHandler?._ref]);

  useEffect(() => {
    setForceRedraw(true);
  }, [zIndex, markup.properties.visible]);

  useEffect(() => {
    if (forceRedraw) {
      setForceRedraw(false);
    }
  }, [forceRedraw]);

  useEffect(() => {
    if (editing && ref?.current) {
      const elem = ref.current;
      if (haveEdited.current) {
        elem.enableEdit();
      } else {
        setTimeout(() => elem.enableEdit(), 100);
        haveEdited.current = true;
      }
      return () => {
        elem.disableEdit();
      };
    }
    return undefined;
  }, [editing, coords, haveEdited]);

  const pane = 'pane-'+markup.properties.id;
  return coords && !forceRedraw && (
    <Pane name={pane} style={{zIndex, display: markup.properties.visible ? 'block' : 'none'}}>
      <Polygon
          ref={ref}
          pane={pane}
          positions={coords}
          pathOptions={{...polygonOptions(markup.properties, z, map, patterns), interactive}}
          eventHandlers={{
            click: interactive ? onClick : () => {},
            'editable:editing': (ev) => commitHandler.edit(ev.layer),
            'editable:disable': (ev) => commitHandler.commit(),
          }}/>
    </Pane>
  );
};

const MapPolylineLayer = ({markup, onClick, interactive, editing, zIndex, tracking}) => {
  const map = useMap();
  const ref = useRef();
  const [z, setZ] = useState(map.getZoom());
  const [coords, setCoords] = useState(null);
  const [forceRedraw, setForceRedraw] = useState(false);
  const haveEdited = useRef(false);

  const arrows = (markup.properties.style ?? '').slice(-6) === '/arrow';
  const commitHandler = useCommitHandler(markup, tracking);

  useEffect(() => {
    const zoom = (ev) => {
      setZ(ev.target.getZoom());
    };
    map.on('zoomend', zoom);
    return () => map.off('zoomend', zoom);
  }, [map]);

  const prevZ = usePrevious(z);
  useEffect(() => {
    if (prevZ !== z || (!editing && commitHandler?._ref?.changed)) {
      commitHandler?.commit();
    }
  }, [prevZ, z, editing, commitHandler]);

  useEffect(() => {
    setCoords(
      arrows && !editing && !commitHandler?._ref?.changed ?
        L.GeoJSON.coordsToLatLngs(arrowizePolylines(markup.geometry.coordinates, markup.properties, z), 1) :
        L.GeoJSON.coordsToLatLngs(markup.geometry.coordinates, markup.geometry.type === 'LineString' ? 0 : 1));
  }, [markup.geometry, markup.properties, arrows, editing, z, commitHandler?._ref]);

  useEffect(() => {
    setForceRedraw(true);
  }, [zIndex, markup.properties.visible]);

  useEffect(() => {
    if (forceRedraw) {
      setForceRedraw(false);
    }
  }, [forceRedraw]);

  useEffect(() => {
    if (editing && ref?.current) {
      const elem = ref.current;
      if (haveEdited.current) {
        elem.enableEdit();
      } else {
        setTimeout(() => elem.enableEdit(), 100);
        haveEdited.current = true;
      }
      return () => {
        elem.disableEdit();
      };
    }
    return undefined;
  }, [editing, coords, haveEdited]);

  const pane = 'pane-'+markup.properties.id;
  return coords && !forceRedraw && (
    <Pane name={pane} style={{zIndex, display: markup.properties.visible ? 'block' : 'none'}}>
      <Polyline
          ref={ref}
          name={pane}
          positions={coords}
          pathOptions={{...polylineOptions(markup.properties, z), interactive}}
          eventHandlers={{
            click: interactive ? onClick : () => {},
            'editable:editing': (ev) => commitHandler.edit(ev.layer),
            'editable:disable': (ev) => commitHandler.commit(),
          }}/>
    </Pane>
  );
};

const MarkerPane = ({markup, zIndex, children}) => {
  const [forceRedraw, setForceRedraw] = useState(false);
  
  useEffect(() => {
    setForceRedraw(true);
  }, [zIndex, markup.properties.visible]);

  useEffect(() => {
    if (forceRedraw) {
      setForceRedraw(false);
    }
  }, [forceRedraw]);

  const pane = 'pane-' + markup.properties.id;
  return !forceRedraw && (
    <Pane name={pane} style={{zIndex, display: markup.properties.visible ? 'block' : 'none'}}>
      {children}
    </Pane>
  );
};

const MarkerProxy = ({markup, coords, index, editing, interactive, click, zIndex, tracking}) => {
  const map = useMap();
  const [z, setZ] = useState(map.getZoom());
  const [clickTimer, setClickTimer] = useState(null);
  const dispatch = useDispatch();
  const hilight = useHighlightedSubMarker();
  const [setUndo] = useUndo();
  const { t } = useTranslation();

  useEffect(() => {
    const zoom = (ev) => {
      setZ(ev.target.getZoom());
    };
    map.on('zoomend', zoom);
    return () => map.off('zoomend', zoom);
  }, [map]);

  const handlers = {};
  if (interactive) {
    handlers.click = click;
  } else if (editing) {
    handlers.click = (ev) => {
      if (clickTimer) {
        clearTimeout(clickTimer);
        setClickTimer(null);
        if (editing) {
          dispatch(highlightSubMarker(null));
          if (markup.geometry.type === 'Point' || markup.geometry.coordinates.length <= 1) {
            setUndo(t('undo-delete-markup'), deleteMarkup(markup, tracking), resurrectMarkup(markup, tracking), dispatch);
          } else {
            const nd = {
              geometry: {
                type: 'MultiPoint',
                coordinates: [
                  ...markup.geometry.coordinates.slice(0, index),
                  ...markup.geometry.coordinates.slice(index+1),
                ],
              }
            };
            const nu = {
              geometry: markup.geometry,
            };
            if (markup.properties.names?.enabled && markup.properties.names?.names?.length >= index) {
              nd.names = {
                enabled: true,
                names: [
                  ...markup.properties.names.names.slice(0, index),
                  ...markup.properties.names.names.slice(index+1),
                ],
              };
              nu.names = markup.properties.names;
            }
            setUndo(t('undo-delete-point'),
                    editMarkup({markup, tracking, ...nd}),
                    editMarkup({markup, tracking, ...nu}),
                    dispatch);
          }
        }
      } else {
        setClickTimer(setTimeout(() => {
          setClickTimer(null);
          dispatch(highlightSubMarker(markup.properties.id, index));
        }, 300));
      }
    };
  }

  handlers.dragend = (ev) => {
    let geo = ev.target.toGeoJSON().geometry;
    if (markup.geometry.type === 'MultiPoint') {
      geo = {
        type: 'MultiPoint',
        coordinates: [
          ...markup.geometry.coordinates.slice(0, index),
          geo.coordinates,
          ...markup.geometry.coordinates.slice(index+1),
        ],
      };
    }
    setUndo(t('undo-markup-geometry'),
            editMarkup({markup, tracking, geometry: geo}),
            editMarkup({markup, tracking, geometry: markup.geometry}),
            dispatch);
  };

  const canEdit = canEditMarkup(markup);
  const hilit = hilight?.markup === markup.properties.id && hilight?.index === index;
  const icon = markerIcon(markup.properties, markerLabel(markup, index), editing, interactive || editing || !canEdit, hilit, z);
  return !icon ? null : (
    <Marker
        pane={'pane-' + markup.properties.id}
        draggable={editing}
        eventHandlers={handlers}
        position={coords}
        icon={icon}>
    </Marker>
  );
};

const MapMarkupLayer = ({markup, zIndex, captureClick, adding, tracking}) => {
  const focused = useFocused();
  const editing = focused?.markup === markup.properties.id && focused.edit && canEditMarkup(markup);
  const freeEdit = editing && freeEditMarkup(markup);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const iid = `MapMarkupLayer/${markup.properties.id}`;
    if (editing) {
      let text;
      let opts;
      if (freeEdit) {
        switch (markup.geometry.type) {
        case 'Polygon':
        case 'MultiPolygon':
          text = t('edit-instructions-polygon');
          break;
        case 'LineString':
        case 'MultiLineString':
          text = t('edit-instructions-line');
          break;
        case 'Point':
        case 'MultiPoint':
          text = t('edit-instructions-point');
          break;
        default:
          break;
        }
      } else {
        text = t('edit-instructions-toggle-polygon');
        if (markup.type === 'FinProperty') {
          opts = {
            setAddingButton: {
              adding: requestAdding('prop-fi-code', adding?.data),
              title: t('prop-fi-search'),
            },
          };
        }
      }
      if (text) {
        dispatch(setInstructionsSnack(text, iid, opts));
        return () => dispatch(dismissInstructionsSnack(iid));
      }
    }
    return undefined;
  }, [editing, freeEdit, dispatch, markup, adding?.data, t]);

  const click = (ev) => {
    dispatch(requestFocus(markup, {edit: true, scrollTo: true}));
    if (captureClick) {
      L.DomEvent.preventDefault(ev);
      L.DomEvent.stopPropagation(ev);
    }
  };

  const interactive = !adding && !focused?.markup;
  switch (markup.geometry.type) {
  case 'Polygon':
  case 'MultiPolygon':
    return (
      <MapPolygonLayer
          zIndex={zIndex}
          markup={markup}
          interactive={interactive}
          editing={freeEdit}
          tracking={tracking}
          onClick={click} />
    );
  case 'LineString':
  case 'MultiLineString':
    return (
      <MapPolylineLayer
          zIndex={zIndex}
          markup={markup}
          interactive={interactive}
          editing={freeEdit}
          tracking={tracking}
          onClick={click}/>
    );
  case 'Point':
    return (
      <MarkerPane markup={markup} zIndex={zIndex}>
        <MarkerProxy
            markup={markup}
            index={0}
            interactive={interactive}
            editing={editing}
            click={click}
            tracking={tracking}
            coords={L.GeoJSON.coordsToLatLng(markup.geometry.coordinates)} />
      </MarkerPane>
    );
  case 'MultiPoint':
    return (
      <MarkerPane markup={markup} zIndex={zIndex}>
        {
          L.GeoJSON.coordsToLatLngs(markup.geometry.coordinates)
            .map((c, i) => (
              <MarkerProxy
                  key={`${markup.properties.id}.${i}`}
                  markup={markup}
                  zIndex={zIndex}
                  index={i}
                  interactive={interactive}
                  editing={editing}
                  click={click}
                  tracking={tracking}
                  coords={c} />
            ))
        }
      </MarkerPane>
    );
  default:
    console.warn('Unidentified geometry type', markup.geometry.type);
    return null;
  }
};

export default MapMarkupLayer;
