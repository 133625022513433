// -*- mode: RJSX; js-indent-level: 2; -*-

import { useState } from 'react';
import { useTheme, makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  Tabs,
  Tab,
} from '@mui/material';
import { ColorPicker, useColor } from 'react-color-palette';
import { withoutAlpha, justAlpha } from '../util';
import 'react-color-palette/dist/css/rcp.css';

const padding = 16;
const sideLength = 50;
const hexMargin = 3;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${padding}px`,
  },
  container: {
    position: 'relative',
  },
  hex: {
    cursor: 'pointer',
  },
  pageContainer: {
    position: 'relative',
    overflow: 'hidden',
  },
  tabPage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    transition: theme.transitions.create('transform'),
  },
  active: {
    transform: 'translateX(0) !important',
  },
}));

const sqrt3 = 1.7320508075688772935274463415059; // sqrt(3)

const Hex = ({side=40, margin=2, color, strokeColor, ...props}) => {
  const h2 = side * sqrt3 / 2;
  const w2 = side / 2;
  const h = Math.ceil(2 * h2) + 2*margin;
  const w = side * 2 + 2*margin;
  return (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        version='1.1'
        width={`${w}px`}
        height={`${h}px`}
        viewBox={`0 0 ${w} ${h}`}
        {...props}>
      <path
          fill={color}
          stroke={strokeColor}
          strokeWidth={side/8}
          d={`M${w2+margin},${margin} l${side},0 l${w2},${h2} l${-w2},${h2} l${-side},0 l${-w2},${-h2} Z`}/>
    </svg>
  );
};

const hexGridWidth = (rings, size) => Math.ceil((3 * rings - 1) * size);
const hexGridHeight = (rings, size) => Math.ceil((2 * rings - 1) * sqrt3 * size);

export const SimpleColorChooser = ({value, onChange, palette, forceRings, ringFactor = [] }) => {
  const theme = useTheme();
  const classes = useStyles();
  const rings = forceRings ?? Math.ceil((sqrt3 * Math.sqrt(4 * palette.length - 1) - 3) / 6 - 1e-9) + 1;
  const w = hexGridWidth(rings, sideLength + hexMargin);
  const h = hexGridHeight(rings, sideLength + hexMargin);
  const hexes = [];
  const valueRGB = withoutAlpha(value);
  let ring = 0;
  let side = 5;
  let sidx = 0;
  for (let i = 0; i < palette.length; i++) {
    const x = -sidx * 3/2;
    const y = sqrt3 * (ring - 0.5*sidx);
    const theta = Math.PI + 2 * Math.PI * side / 6;
    const px = Math.round(
      (x * Math.cos(theta) - y * Math.sin(theta)) *
        (sideLength + hexMargin) +
        w/2 - (sideLength + hexMargin) * (ringFactor[ring] || 1));
    const py = Math.round(
      (x * Math.sin(theta) + y * Math.cos(theta)) *
        (sideLength + hexMargin) +
        h/2 - (sideLength + hexMargin) * sqrt3/2 * (ringFactor[ring] || 1));
    hexes.push(
      <div key={palette[i]}
           style={{
             left: `${px}px`,
             top: `${py}px`,
             position: 'absolute',
           }}>
        <Hex side={sideLength * (ringFactor[ring] || 1)}
             margin={hexMargin}
             color={palette[i]}
             strokeColor={valueRGB.toUpperCase() === palette[i].toUpperCase() ? theme.palette.primary.main : 'transparent'}
             className={classes.hex}/>
      </div>
    );
    sidx++;
    if (sidx >= ring) {
      sidx = 0;
      side++;
      if (side >= 6) {
        side = 0;
        ring++;
      }
    }
  }
  return (
    <div className={classes.root}>
      <div
          className={classes.container}
          style={{width: `${w}px`, height: `${h}px`}}
          onClick={(ev) => {
            if (ev.target?.localName === 'path') {
              const colorRGB = ev.target.attributes?.getNamedItem('fill')?.value;
              let color = colorRGB;
              if (value[0] === '#' && value.length > 7) {
                // copy alpha
                color = '#' + justAlpha(value, 0.5, true) + color.slice(1);
              }
              if (color && onChange) {
                onChange(color);
              }
            }
          }}>
        {hexes}
      </div>
    </div>
  );
};

export const CustomColorChooser = ({width, height, value, onChange}) => {
  const [c, setC] = useColor(withoutAlpha(value));
  const classes = useStyles();

  return (
    <div className={classes.root}
         onMouseUp={(ev) => {
           if(ev.target.attributes?.getNamedItem('class')?.value === 'rcp-saturation-cursor') {
             let color = c.hex;
             if (value[0] === '#' && value.length > 7) {
               // copy alpha
               color = '#' + justAlpha(value, 0.5, true) + color.slice(1);
             }
             if (color && onChange) {
               onChange(color);
             }
           }
         }}>
      <ColorPicker
          height={height - 50}
          color={c}
          onChange={(c) => setC(c)}
          hideInput
          hideAlpha />
    </div>
  );
};

export const ColorChooser = ({value, onChange, palette}) => {
  const [tab, setTab] = useState(0);
  const { t } = useTranslation();
  const classes = useStyles();

  const w = hexGridWidth(3, sideLength + hexMargin);
  const h = hexGridHeight(3, sideLength + hexMargin);
  console.log('value is', value, 'palette', palette);
  return (
    <>
      <Tabs variant='fullWidth' value={tab} onChange={(ev, val) => setTab(val)} indicatorColor='primary'>
        <Tab label={t('simple')}/>
        <Tab label={t('custom')}/>
      </Tabs>
      <div className={classes.pageContainer}
           style={{width: w + 2*padding, height: h + 2*padding}}>
        <div className={clsx(classes.tabPage, tab === 0 && classes.active)}
             style={{transform: 'translateX(-100%)'}}>
          <SimpleColorChooser
              value={value}
              onChange={onChange}
              palette={palette}
              forceRings={3}
              ringFactor={[1, 1, 0.75]}/>
        </div>
        <div className={clsx(classes.tabPage, tab === 1 && classes.active)}
             style={{transform: 'translateX(100%)'}}>
          <CustomColorChooser
              value={value}
              onChange={onChange}
              width={w}
              height={h}/>
        </div>
      </div>
    </>
  );
};

export default ColorChooser;
