// -*- mode: RJSX; js-indent-level: 2; -*-

import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import {
  ImageList,
  ImageListItem,
  ButtonBase,
  Typography,
} from '@mui/material';
import CheckBoxEmpty from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxChecked from '@mui/icons-material/CheckBox';
import TargetIcon, { ScaledIcon } from '../icons/TargetStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1em',
  },
  border: {
    border: '3px solid transparent',
    transition: theme.transitions.create('border-color', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  selected: {
    border: `3px solid ${theme.palette.primary.main}`,
  },
  none: {
    width: '100%',
    height: '100%',
  },
  noneLabel: {
    paddingLeft: '0.25em',
  },
}));

const StyleChooser = ({value, onChange, styles, color, none}) => {
  const classes = useStyles();
  const cols = styles.length < 6 ? styles.length : 4;
  return (
    <div className={classes.root}>
      <ImageList rowHeight={53} cols={cols} sx={{ margin: 0 }}>
        {
          !!none &&
          <ImageListItem cols={cols}>
            <ButtonBase
                onClick={() => onChange && onChange('none')}>
              {value === 'none' ? <CheckBoxChecked/> : <CheckBoxEmpty/>}
              <Typography variant='body1' className={classes.noneLabel}>
                {none}
              </Typography>
            </ButtonBase>
          </ImageListItem>
        }
        {styles.map((s) => (
          <ImageListItem key={s}>
            <div className={clsx(classes.border, value === s && classes.selected)}>
              <ButtonBase onClick={() => onChange && onChange(s)}>
                <ScaledIcon size={44}>
                  <TargetIcon id={s} color={color}/>
                </ScaledIcon>
              </ButtonBase>
            </div>
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
};

export default StyleChooser;
