// -*- mode: RJSX; js-indent-level: 2; -*-

import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import GlobaIcon from '@mui/icons-material/Public';
import MapButton from './MapButton';

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    right: '1em',
    top: '1em',
  },
}));

const LayersControl = ({onClick}) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.container, 'leaflet-control')}>
      <MapButton onClick={onClick}>
        <GlobaIcon/>
      </MapButton>
    </div>
  );
};

export default LayersControl;
