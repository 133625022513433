// -*- mode: RJSX; js-indent-level: 2; -*-

import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Slider,
  Avatar,
} from '@mui/material';
import { textForBackground } from '../util';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1em',
  },
}));

const SizeChooser = ({value, min, max, step = 1, onChange, color}) => {
  const classes = useStyles();
  const [current, setCurrent] = useState(value);

  return (
    <div className={classes.root} spacing={5}>
      <Box display='flex' flexDirection='row'>
        <Box alignSelf='center'>
          <Slider
              sx={{ width: '200px' }}
              value={current}
              step={step}
              min={min}
              max={max}
              onChange={(ev, val) => setCurrent(val)}
              onChangeCommitted={() => onChange(current)}/>
        </Box>
        <Box alignSelf='center' paddingLeft='2em'>
          <Avatar
              style={{
                backgroundColor: color,
                color: textForBackground(color),
              }}>
            {current}
          </Avatar>
        </Box>
      </Box>
    </div>
  );
};

export default SizeChooser;
