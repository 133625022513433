// -*- mode: RJSX; js-indent-level: 2; -*-

import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { logout } from './user';
import { createApiAsyncThunk, apiRequest } from './api';
import { debug } from '../util';

const validateState = (state) => {
  if (!state?.lookup) {
    return;
  }
  let ok = true;
  const seen = {};
  const validateChildren = (parent, list) => {
    list.forEach((m) => {
      if (!m?.properties) {
        console.warn('Broken markup', m);
        ok = false;
        return;
      }
      if (m.properties.folder !== parent) {
        console.warn('Validation error: markup', m, 'in incorrect path in tree, parent', parent);
        ok = false;
      }
      if (state.lookup[m.properties.id] !== m) {
        if (!state.lookup[m.properties.id]) {
          console.warn('Validation error: markup', m, 'not found in lookup');
        } else {
          console.warn('Validation error: markup', m, 'inequal to its lookup instance', state.lookup[m.properties.id]);
        }
        ok = false;
      }
      seen[m.properties.id] = true;
      if (m.children) {
        validateChildren(m.properties.id, m.children);
      }
    });
  };
  validateChildren(null, state.background);
  validateChildren(null, state.area);
  validateChildren(null, state.point);
  for (let k in state.lookup) {
    if (!seen[k]) {
      console.warn('Validation error: markup', state.lookup[k], 'not seen in tree');
      ok = false;
    }
  }
  if (!ok) {
    console.log('Background', state.background);
    console.log('Area', state.area);
    console.log('Point', state.point);
    console.log('Lookup', state.lookup);
  }
};

export const useMarkup = (design, id) => useSelector((state) => {
  if (debug) {
    validateState(state.markup[design]);
  }
  if (!id) {
    return state.markup[design];
  }
  if (design) {
    if (state.markup[design]?.lookup) {
      return state.markup[design].lookup[id];
    }
    return null;
  }
  for (let d in state.markup) {
    const m = state.markup[d].lookup[id];
    if (m) {
      return m;
    }
  }
  return undefined;
});

export const useWorking = (design) => useSelector((state) => state.markup[design]?.working);

export const isBackgroundMarkup = (m) => ['Reviiri', 'OmaRiista', 'FinProperty', 'FinStateHunting', 'SweProperty'].indexOf(m.type) >= 0;
export const isAreaMarkup = (m) => m.type === 'Feature' && m.geometry.type !== 'Point' && m.geometry.type !== 'MultiPoint';
export const isPointMarkup = (m) => m.type === 'Feature' && (m.geometry.type === 'Point' || m.geometry.type === 'MultiPoint');
export const isFolder = (m) => m.type === 'Folder';

export const canEditMarkup = (markup) => (markup.type === 'Feature' || markup.type === 'FinProperty' || markup.type === 'SweProperty') && !markup.properties.linkedTo;
export const freeEditMarkup = (markup) => markup.type === 'Feature';

const markupCollection = (m) => {
  if (isBackgroundMarkup(m)) {
    return 'background';
  } else if (isAreaMarkup(m)) {
    return 'area';
  } else {
    return 'point';
  }
};

const spliceIntoTree = (tree, path, item) => {
  if (!path.length) {
    // Insert here
    return [
      [
        ...tree.filter((m) => m.properties.z > item.properties.z),
        item,
        ...tree.filter((m) => m.properties.z <= item.properties.z),
      ],
      {},
    ];
  }
  // Insert at child level
  let mapOut = {};
  const treeOut = tree.map((m) => {
    if (m.properties.id === path[0]) {
      const [ch, chmap] = spliceIntoTree(m.children, path.slice(1), item);
      m = {...m, children: ch};
      mapOut = {...mapOut, ...chmap, [m.properties.id]: m};
    }
    return m;
  });
  return [treeOut, mapOut];
};

const deleteFromTree = (tree, path, id) => {
  if (!path.length) {
    // Delete from here
    return [
      tree.filter((m) => m.properties.id !== id),
      {},
    ];
  }
  // Delete from children
  let mapOut = {};
  const treeOut = tree.map((m) => {
    if (m.properties.id === path[0]) {
      const [ch, chmap] = deleteFromTree(m.children, path.slice(1), id);
      m = {...m, children: ch};
      mapOut = {...mapOut, ...chmap, [m.properties.id]: m};
    }
    return m;
  });
  return [treeOut, mapOut];
};

const parseFolderPath = (lookup, fid) => {
  const path = [];
  let f = lookup[fid];
  while (f?.properties?.id && isFolder(f)) {
    path.push(f.properties.id);
    f = lookup[f.properties.folder];
  }
  return path.reverse();
};

const pushToContents = (current, item) => {
  if (isFolder(item)) {
    item = {...item, children: []};
  }
  const lookup = {...current.lookup, [item.properties.id]: item};

  // Form the folder traversal path for the new item
  const path = parseFolderPath(lookup, item.properties.folder);
  const key = markupCollection(item);
  const [tree, updates] = spliceIntoTree(current[key], path, item);
  return {
    ...current,
    [key]: tree,
    lookup: {...lookup, ...updates},
  };
};

const deleteFromContents = (current, path, item) => {
  const key = markupCollection(item);
  const [tree, mapOut] = deleteFromTree(current[key], path, item.properties.id);
  return [
    {
      [key]: tree,
    },
    mapOut,
  ];
};

const updateContentsItem = (current, id, item) => {
  const prev = current.lookup[id];
  if (!prev) {
    console.warn('Attempt to update markup not found in state data!', id, item);
    return current;
  }
  if (item.type && prev.type !== item.type) {
    console.warn('Attempt to change markup type!');
    return current;
  }
  const prevFolder = prev.properties.folder;
  item = {
    ...prev,
    ...item,
    geometry: item.geometry ?? prev.geometry,
    properties: {
      ...prev.properties,
      ...item.properties,
    },
  };
  const from = parseFolderPath(current.lookup, prevFolder);
  const to = parseFolderPath(current.lookup, item.properties.folder);
  const key = markupCollection(item);
  const [removed, rUpdates] = deleteFromTree(current[key], from, item.properties.id);
  const [tree, iUpdates] = spliceIntoTree(removed, to, item);
  return {
    ...current,
    [key]: tree,
    lookup: {
      ...current.lookup,
      ...rUpdates,
      ...iUpdates,
      [item.properties.id]: item,
    },
  };
};

const zRangeValid = (list, min, max) => {
  for (let i = 0; i < list.length; i++) {
    if (min !== undefined && list[i].properties.z < min) {
      return false;
    }
    if (max !== undefined && list[i].properties.z > max) {
      return false;
    }
    if (i > 0 && list[i-1].properties.z === list[i].properties.z) {
      return false;
    }
  }
  return true;
};

const stepFromLen = (len) => Math.max(Math.min(Math.floor(250000/len), 500), 1)*2;
const reZ = (list, num, step, updates) => {
  num += step * list.length;
  list.forEach((m) => {
    num -= step;
    if (m.properties.z !== num) {
      m.properties.z = num;
      updates[m.properties.id] = {
        ...(updates[m.properties.id] ?? {}),
        z: num,
      };
    }
  });
};

const sanitizeMarkup = async (design, markup, tracking) => {
  // Sanitize and update the server's markup list so that it
  // fulfills the expectations of the current Trapmap view of the world:
  // - Z order ranges:
  //   - -∞ to 1M: background
  //   - 1M to 2M: areas
  //   - 2M to 3M: folders
  //   - 3M to +∞: folderless points
  //   - Points in folders: not restricted (except for uniqueness)
  // - Folders can only contain point markup.
  // Additionally, restructure the flat response data according
  // to what markup.js expects.

  // Required updates
  const updates = {};
  // Toplevel containers
  const back = [];
  const area = [];
  const point = [];

  // First, make a lookup, adding child arrays for folders, and add folders into the root container.
  // Also add the design ID to every marker object.
  const lookup = {};
  markup.forEach((m) => m.design = design);
  markup.filter(isFolder).forEach((m) => {
    m.children = [];
    lookup[m.properties.id] = m;
    point.push(m);
  });
  // Then, sort all markup into their parent folders
  markup.forEach((m) => {
    if (!isPointMarkup(m) && m.properties.folder) {
      // Disallow markup other than points in folders
      m.properties.folder = null;
      updates[m.properties.id] = {
        folder: null,
      };
    }

    if (isBackgroundMarkup(m)) {
      back.push(m);
    } else if (isAreaMarkup(m)) {
      area.push(m);
    } else if (isPointMarkup(m)) {
      const f = lookup[m.properties.folder];
      if (f && isFolder(f)) {
        f.children.push(m);
      } else {
        if (m.properties.folder) {
          // Invalid folder ID for this markup
          m.properties.folder = null;
          updates[m.properties.id] = {
            folder: null,
          };
        }
        point.push(m);
      }
    }
  });

  // Sort containers and folders
  back.sort((a, b) => b.properties.z - a.properties.z);
  area.sort((a, b) => b.properties.z - a.properties.z);
  point.sort((a, b) => b.properties.z - a.properties.z);
  for (let f in lookup) {
    lookup[f].children.sort((a, b) => b.properties.z - a.properties.z);
  }

  // Augment the lookup with all markup
  back.forEach((m) => lookup[m.properties.id] = m);
  area.forEach((m) => lookup[m.properties.id] = m);
  point.forEach((m) => lookup[m.properties.id] = m);
  point.filter(isFolder).forEach((f) => f.children.forEach((m) => lookup[m.properties.id] = m));

  // Verify Z restrictions
  if (!zRangeValid(back, undefined, 1000000)) {
    reZ(back, 500000 - back.length*1000, 1000, updates);
  }
  if (!zRangeValid(area, 1000000, 2000000)) {
    const step = stepFromLen(area.length);
    reZ(area, 1500000 - step*area.length/2, step, updates);
  }
  const fpoint = point.filter(isFolder);
  if (!zRangeValid(fpoint, 2000000, 3000000)) {
    const step = stepFromLen(fpoint.length);
    reZ(fpoint, 2500000 - step*fpoint.length/2, step, updates);
  }
  const ppoint = point.filter(isPointMarkup);
  if (!zRangeValid(ppoint, 3000000, undefined)) {
    reZ(ppoint, 3500000, 1000, updates);
  }
  fpoint.forEach((f) => {
    if (!zRangeValid(f.children, undefined, undefined)) {
      reZ(f.children, 0, 1000, updates);
    }
  });

  // Apply changes, if any
  const updateData = {};
  let anyUpdates = false;
  for (let id in updates) {
    updateData[id] = {
      properties: updates[id],
    };
    anyUpdates = true;
  }
  if (anyUpdates) {
    await apiRequest({
      method: 'post',
      url: `/api/v1/design/${design}/markup/edit` + (tracking ? `?tracking=${tracking}` : ''),
      input: updateData,
    });
  }

  // Then return the restructured data
  return {
    background: back,
    area: area,
    point: point,
    lookup: lookup
  };
};

const validateEditMarkupArgs = ({design, id, markup, geometry, tracking, ...props}) => {
  if (!design) {
    design = markup?.design;
  }
  if (!id) {
    id = markup?.properties?.id;
  }
  if (!design || !id) {
    console.warn('No design/markup ID provided to editMarkup');
    return {};
  }
  const data = {};
  if (geometry) {
    data.geometry = geometry;
  }
  if (props) {
    for (let _ in props) { // eslint-disable-line no-unused-vars
      // any key => include properties
      data.properties = props;
      break;
    }
  }
  if (!data.geometry && !data.properties) {
    console.warn('No geometry or properties to update in editMarkup');
    return {};
  }
  return {design, id, tracking, data};
};

export const refreshDesignContents = createApiAsyncThunk('design/load', (id, tracking) => ({id, tracking}), {
  url: ({id}) => `/api/v1/design/${id}/markup/list`,
  ok: ({markup}, {id, tracking}) => sanitizeMarkup(id, markup, tracking),
  fail: (arg, status) => status === 404 ? { notFound: true } : null,
});

export const createNewMarkup = (design, data, tracking) => apiRequest({
  method: 'put',
  url: `/api/v1/design/${design}/markup/new` + (tracking ? `?tracking=${tracking}` : ''),
  input: data,
  verify: (resp) => resp.data?.status === 'SUCCESS',
  ok: ({status, ...out}) => out,
});

export const fetchOmariistaMarkup = (design, code, z, markupZ, tracking) => apiRequest({
  method: 'put',
  url: `/api/v1/design/${design}/markup/ext/omariista` + (tracking ? `?tracking=${tracking}` : ''),
  input: {
    areaCode: code,
    z: z,
    ...(markupZ ? {markupZ: markupZ} : {}),
  },
  verify: (resp) => resp.data?.status === 'SUCCESS',
  ok: ({status, ...out}) => out,
});

export const fetchPropFiEstate = (design, markup, code, tracking) => {
  const {id, folder, ...props} = markup?.properties ?? {};
  return apiRequest({
    method: 'post',
    url: `/api/v1/design/${design}/markup/ext/propfi` + (tracking ? `?tracking=${tracking}` : ''),
    input: {
      markup: typeof(markup) === 'number' ? markup : null,
      properties: markup?.properties ? props : null,
      regNumber: code,
    },
    verify: (resp) => resp.data?.status === 'SUCCESS',
  });
};

export const fetchPropFiParcel = (design, markup, location, tracking) => {
  const {id, folder, ...props} = markup?.properties ?? {};
  return apiRequest({
    method: 'post',
    url: `/api/v1/design/${design}/markup/ext/propfi` + (tracking ? `?tracking=${tracking}` : ''),
    input: {
      markup: typeof(markup) === 'number' ? markup : null,
      properties: markup?.properties ? props : null,
      at: location,
    },
    verify: (resp) => resp.data?.status === 'SUCCESS',
  });
};

export const fetchPropSeParcel = (design, markup, location, tracking) => {
  const {id, folder, ...props} = markup?.properties ?? {};
  return apiRequest({
    method: 'post',
    url: `/api/v1/design/${design}/markup/ext/propse` + (tracking ? `?tracking=${tracking}` : ''),
    input: {
      markup: typeof(markup) === 'number' ? markup : null,
      properties: markup?.properties ? props : null,
      at: location,
    },
    verify: (resp) => resp.data?.status === 'SUCCESS',
  });
};

export const fetchStateHuntingFiRegion = (design, location, z, tracking) => apiRequest({
  method: 'put',
  url: `/api/v1/design/${design}/markup/ext/shfi` + (tracking ? `?tracking=${tracking}` : ''),
  input: {
    at: location,
    z: z,
  },
  verify: (resp) => resp.data?.status === 'SUCCESS',
});

export const fetchUserMarkers = () => apiRequest({
  url: `/api/v1/user/markers`,
  verify: (resp) => resp.data?.status === 'SUCCESS',
  ok: ({personal, groups}) => ({personal, groups}),
});

export const importUserMarkers = (ids, tracking) => apiRequest({
  method: 'post',
  url: `/api/v1/user/markers` + (tracking ? `?tracking=${tracking}` : ''),
  input: {
    markers: ids,
  },
  verify: (resp) => resp.data?.status === 'SUCCESS',
  ok: ({markers}) => markers,
});

export const editMarkup = createApiAsyncThunk('markup/edit', (args) => validateEditMarkupArgs(args), {
  method: 'post',
  url: ({design, id, tracking}) => `/api/v1/design/${design}/markup/${id}` + (tracking ? `?tracking=${tracking}` : ''),
  input: ({data}) => data,
  verify: (resp) => resp.data?.status === 'SUCCESS',
  ok: ({status, ...out}) => out,
});

export const refreshMarkup = createApiAsyncThunk('markup/refresh', (markup, tracking, newZ) => ({markup, tracking, newZ}), {
  url: ({markup, tracking, newZ}) =>
    `/api/v1/design/${markup.design}/markup/${markup.properties.id}/refresh` +
    (tracking || newZ ? '?' : '') +
    (newZ ? `newz=${newZ}` : '') +
    (tracking && newZ ? '&' : '') +
    (tracking ? `tracking=${tracking}` : ''),
  verify: (resp) => resp.data?.status === 'SUCCESS',
  ok: ({status, ...out}) => out,
});

export const deleteMarkup = createApiAsyncThunk('markup/delete', (markup, tracking) => ({markup, tracking}), {
  method: 'delete',
  url: ({markup, tracking}) =>
    `/api/v1/design/${markup.design}/markup/${markup.properties.id}` +
    (tracking ? `?tracking=${tracking}` : ''),
});

export const resurrectMarkup = createApiAsyncThunk('markup/resurrect', (markup, tracking) => ({markup, tracking}), {
  method: 'put',
  url: ({markup, tracking}) =>
    `/api/v1/design/${markup.design}/markup/${markup.properties.id}/resurrect` +
    (tracking ? `?tracking=${tracking}` : ''),
});

const reorderMarkupInternal = createApiAsyncThunk('markup/multiedit', (design, data, tracking) => ({design, data, tracking}), {
  method: 'post',
  url: ({design, tracking}) => `/api/v1/design/${design}/markup/edit` + (tracking ? `?tracking=${tracking}` : ''),
  input: ({data}) => data,
});

export const reorderMarkup = (designID, fullMarkupData, markupID, targetID, targetIndex, tracking) => {
  const none = () => null;
  const state = fullMarkupData;
  if (debug) {
    validateState(state);
  }
  markupID = parseInt(markupID);
  if (!state) {
    console.warn('In reorderMarkup, design data not found', designID);
    return none;
  }
  const markup = state.lookup[markupID];
  if (!markup) {
    console.warn('In reorderMarkup, markup data not found', designID, markupID);
    return none;
  }
  const key = markupCollection(markup);
  const from = !markup.properties.folder ? state[key] : state.lookup[markup.properties.folder]?.children;
  if (!from) {
    console.warn('In reorderMarkup, source folder not found', markup.properties.folder);
    return none;
  }
  const fromIndex = from.indexOf(markup);
  if (fromIndex < 0) {
    console.warn('In reorderMarkup, markup not found in its folder', markup, from);
    return none;
  }
  const to = targetID === 'root' ? state[key] : state.lookup[targetID]?.children;
  if (!to) {
    console.warn('In reorderMarkup, target folder not found', targetID);
    return none;
  }

  if (from === to && targetIndex === fromIndex) {
    // Not moved
    return none;
  }
  let changes = {};
  const mch = {properties: {}};
  if (from !== to) {
    mch.properties.folder = targetID === 'root' ? null: parseInt(targetID);
  }

  // Determine the new Z range
  let min, max;
  if (isBackgroundMarkup(markup)) {
    max = 1000000;
  } else if (isAreaMarkup(markup)) {
    min = 1000000;
    max = 2000000;
  } else if (isFolder(markup)) {
    min = 2000000;
    max = 3000000;
  } else if (targetID === 'root') {
    min = 3000000;
  }
  const fmin = min;
  const fmax = max;
  if (from !== to || targetIndex < fromIndex) {
    if (targetIndex > 0) {
      max = to[targetIndex-1].properties.z;
    }
    if (targetIndex < to.length) {
      min = to[targetIndex].properties.z;
    }
  } else {
    max = to[targetIndex].properties.z;
    if (targetIndex < to.length-1) {
      min = to[targetIndex+1].properties.z;
    }
  }

  if (min && max && max-min < 2) {
    // no space, must renumber
    let num, step;
    if (fmin === undefined) {
      if (fmax === undefined) {
        step = -1000;
        num = 0;
      } else {
        step = -1000;
        num = fmax - 500000;
      }
    } else {
      if (fmax === undefined) {
        step = -1000;
        num = fmin + 500000 - step * to.length;
      } else {
        step = -Math.max(Math.min(Math.floor(250000/to.length), 500), 1)*2;
        num = (fmin+fmax)/2 - step*to.length/2;
      }
    }
    let i = 0;
    for (; i < targetIndex; i++) {
      changes[to[i].properties.id] = {properties: {z: num}};
      num += step;
    }
    mch.properties.z = num;
    num += step;
    for (; i < to.length; i++) {
      changes[to[i].properties.id] = {properties: {z: num}};
      num += step;
    }
  } else {
    if (min === undefined) {
      if (max === undefined) {
        // Target is empty folder
        mch.properties.z = 0;
      } else {
        // No floor
        mch.properties.z = max - 1000;
      }
    } else {
      if (max === undefined) {
        // No ceiling
        mch.properties.z = min + 1000;
      } else {
        mch.properties.z = Math.floor((min + max) / 2);
      }
    }
  }
  changes[markupID] = mch;
  return reorderMarkupInternal(designID, changes, tracking);
};

export const nextZ = (list, collection, folder) => {
  let min, max;
  switch (collection) {
  case 'background':
    max = 1000000;
    break;
  case 'area':
    min = 1000000;
    max = 2000000;
    break;
  case 'point':
    if (folder) {
      min = 2000000;
      max = 3000000;
    } else {
      min = 3000000;
      max = 4000000;
    }
    break;
  case 'folder':
    break;
  default:
    console.error('Invalid collection for nextZ', collection);
    return 0;
  }

  if (!list.length) {
    return min ? (max ? (min+max)/2 : min + 500000) : (max ? max - 500000 : 0);
  }

  const last = list[list.length-1].properties.z;
  if (last - min > 100000) {
    return last - 1000;
  } else if (last - min > 10000) {
    return last - 100;
  } else if (last - min > 1000) {
    return last - 10;
  } else if (last - min > 1) {
    return last - 1;
  } else {
    // TODO: renumbering needed
    return last - 1;
  }
};

export const nextNewID = (() => {
  let lastID = 0;
  return () => {
    lastID++;
    return `new-${lastID}`;
  };
})();

export const markerLabel = (markup, index) => {
  let name = markup.properties.name ?? '';
  if (markup.geometry.type === 'MultiPoint' && markup.geometry.coordinates.length > 1) {
    const specific = markup.properties.names?.enabled && markup.properties.names.names[index];
    if (specific) {
      name = specific;
    } else {
      if (name.length > 1) {
        name += ' ';
      }
      name += `${index+1}`;
    }
  }
  return name;
};

const removeMarkup = (state, design, markup) => {
  const current = state[design];
  if (current) {
    const path = parseFolderPath(current.lookup, markup.properties.folder);
    // For some reason, spread syntax does not work here. Due to proxies? Anyway, loop instead.
    const lookup = {};
    for (let k in current.lookup) {
      if (k !== '' + markup.properties.id) {
        lookup[k] = current.lookup[k];
      }
    }
    try {
      const [tree, updates] = deleteFromContents(current, path, markup);
      const out = {
        ...state,
        [design]: {
          ...state[design],
          ...tree,
          lookup: {...lookup, ...updates},
        },
      };
      return out;
    } catch(ex) {
      console.error(ex);
      throw ex;
    }
  }
  return state;
};

export const markupSlice = createSlice({
  name: 'markup',
  initialState: {},
  reducers: {
    newMarkupPending: {
      prepare: (design, pending) => ({payload: {design, pending}}),
      reducer: (state, action) => {
        const { design, pending } = action.payload;
        return {
          ...state,
          [design]: {
            ...state[design],
            working: pending,
          },
        };
      },
    },
    newMarkup: (state, action) => {
      const { design } = action.payload;
      const markup = action.payload;
      if (!state[design]) {
        console.warn('Add to nonexistent design', design, markup);
        return state;
      } else {
        return {
          ...state,
          [design]: {
            ...pushToContents(state[design], markup),
            working: false,
          },
        };
      }
    },
    deletePlaceholder: (state, action) => {
      const markup = action.payload;
      const design = markup.design;
      const current = state[design];
      if (current) {
        const path = parseFolderPath(current.lookup, markup.properties.folder);
        const {[markup.properties.id]: _, ...lookup} = current.lookup; // eslint-disable-line no-unused-vars
        const [tree, updates] = deleteFromContents(current, path, markup);
        return {
          ...state,
          [design]: {
            ...state[design],
            ...tree,
            lookup: {...lookup, ...updates},
          },
        };
      } else {
        return state;
      }
    },
    markupChanged: (state, action) => {
      const { design } = action.payload;
      const markup = action.payload;
      if (!state[design]) {
        console.warn('Attempt to edit markup of nonexistent design', design, markup);
        return state;
      }
      return {
        ...state,
        [design]: updateContentsItem(state[design], markup.properties.id, markup),
      };
    },
    invalidateDesignContents: (state, action) => {
      const { [action.payload]: _,  ...rest } = state;
      return rest;
    },
    markupUpdated: {
      prepare: (design, markup) => ({payload: {design, markup}}),
      reducer: (state, action) => {
        const {design, markup} = action.payload;
        const current = state[design];
        if (!current) {
          console.warn('Tracking change for a nonexistent design', design, markup);
          return state;
        }
        const old = current.lookup[markup.properties.id];
        if (!old) {
          // New markup
          if (!markup.geometry) {
            // ...but deleted already
            return state;
          } else {
            return {
              ...state,
              [design]: pushToContents(current, markup),
            };
          }
        } else if (!markup.geometry) {
          // Markup deleted
          return removeMarkup(state, design, old);
        } else {
          // Markup changed
          return {
            ...state,
            [design]: updateContentsItem(current, markup.properties.id, markup),
          };
        }
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(refreshDesignContents.fulfilled, (state, action) => {
        if (action.payload && !action.payload.notFound) {
          return {
            ...state,
            [action.meta.arg.id]: {
              ...(action.payload ?? {}),
              working: false,
            },
          };
        }
        return state;
      })
      .addCase(editMarkup.pending, (state, action) => {
        const {design, id, data} = action.meta.arg;
        if (!id) {
          // Prevent sending of the request as well
          throw new Error('abort mission');
        }
        return {
          ...state,
          [design]: {
            ...updateContentsItem(state[design], id, data),
            working: true,
          },
        };
      })
      .addCase(editMarkup.fulfilled, (state, action) => {
        const {design, id} = action.meta.arg;
        if (id) {
          if (action.payload) {
            return {
              ...state,
              [design]: {
                ...updateContentsItem(state[design], id, action.payload),
                working: false,
              },
            };
          } else {
            return {
              ...state,
              [design]: {
                ...state[design],
                working: false,
              },
            };
          }
        }
        return state;
      })
      .addCase(refreshMarkup.pending, (state, action) => {
        const markup = action.meta.arg?.markup;
        const dstate = state[markup?.design];
        const id = markup?.properties?.id;
        if (!dstate || !id) {
          console.log('abort mission due to', dstate, id);
          throw new Error('abort mission');
        }
        return {
          ...state,
          [markup.design]: {
            ...dstate,
            working: true,
          },
        };
      })
      .addCase(refreshMarkup.fulfilled, (state, action) => {
        const markup = action.meta.arg?.markup;
        const dstate = state[markup?.design];
        const id = markup?.properties?.id;
        if (dstate && id) {
          if (action.payload) {
            const {linked, ...parent} = action.payload;
            let updated = updateContentsItem(dstate, id, parent);
            for (const k in (linked ?? {})) {
              const id = parseInt(k);
              if (id) {
                if (linked[k]) {
                  if (updated.lookup[id]) {
                    updated = updateContentsItem(updated, id, linked[k]);
                  } else {
                    updated = pushToContents(updated, linked[k]);
                  }
                } else {
                  const {[id]: _, ...lookup} = updated.lookup; // eslint-disable-line no-unused-vars
                  const [tree, updates] = deleteFromContents(updated, [], updated.lookup[id]);
                  updated = {
                    ...updated,
                    ...tree,
                    lookup: {...lookup, ...updates},
                  };
                }
              }
            }
            return {
              ...state,
              [markup?.design]: {
                ...updated,
                working: false,
              },
            };
          } else {
            return {
              ...state,
              [markup?.design]: {
                ...dstate,
                working: false,
              },
            };
          }
        }
        return state;
      })
      .addCase(editMarkup.rejected, (state, action) => {
        const {design, id} = validateEditMarkupArgs(action.meta.arg);
        if (id) {
          return {
            ...state,
            [design]: {
              ...state[design],
              working: false,
            },
          };
        }
        return state;
      })
      .addCase(reorderMarkupInternal.pending, (state, action) => {
        const {design, data} = action.meta.arg;
        let dstate = state[design];
        for (let id in data) {
          dstate = updateContentsItem(dstate, parseInt(id), data[id]);
        }
        return {
          ...state,
          [design]: {
            ...dstate,
            working: true,
          },
        };
      })
      .addCase(reorderMarkupInternal.fulfilled, (state, action) => {
        const {design} = action.meta.arg;
        return {
          ...state,
          [design]: {
            ...state[design],
            working: false,
          },
        };
      })
      .addCase(reorderMarkupInternal.rejected, (state, action) => {
        const {design} = action.meta.arg;
        return {
          ...state,
          [design]: {
            ...state[design],
            working: false,
          },
        };
      })
      .addCase(deleteMarkup.pending, (state, action) => {
        const markup = action.meta.arg.markup;
        const design = markup.design;
        return removeMarkup(state, design, markup);
      })
      .addCase(resurrectMarkup.pending, (state, action) => {
        const markup = action.meta.arg.markup;
        const design = markup.design;
        const current = state[design];
        if (current) {
          return {
            ...state,
            [design]: {
              ...pushToContents(current, markup),
              working: true,
            },
          };
        }
        return state;
      })
      .addCase(resurrectMarkup.fulfilled, (state, action) => {
        const markup = action.meta.arg;
        const design = markup.design;
        const current = state[design];
        if (current && action.payload) {
          return {
            ...state,
            [design]: {
              ...updateContentsItem(current, markup.properties.id, action.payload),
              working: false,
            },
          };
        }
        return state;
      })
      .addCase(logout, (state, action) => ({}))
      .addDefaultCase((state, action) => state);
  }
});

export const {
  newMarkupPending,
  newMarkup,
  markupChanged,
  deletePlaceholder,
  invalidateDesignContents,
  markupUpdated,
} = markupSlice.actions;

export default markupSlice.reducer;
