// -*- mode: RJSX; js-indent-level: 2; -*-

import { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  CircularProgress,
  Button,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useUser, startQRLogin, combineAccounts } from '../state/user';

const useStyles = makeStyles(() => ({
  instructions: {
    marginRight: '2em',
  },
  strong: {
    fontWeight: 'bold',
  },
}));

const CombineAccountsDialog = ({open, onClose, onCompleted}) => {
  const classes = useStyles();
  const { brands } = useUser();
  const { t } = useTranslation();
  const [qrcode, setQRCode] = useState(null);
  const [failed, setFailed] = useState(null);
  const [success, setSuccess] = useState(false);
  const socket = useRef(null);

  const currentBrand = brands && brands[0];
  const otherBrand = currentBrand && (['Tracker', 'Ultracom'].filter((b) => b !== currentBrand)[0]);

  useEffect(() => {
    if (!open) {
      return undefined;
    }
    setQRCode(null);
    setFailed(null);
    setSuccess(false);
    (async () => {
      socket.current = await startQRLogin();
      if (!socket.current) {
        setFailed('communications');
      } else {
        socket.current.onopen = (ev) => {
          socket.current.send('{"request":"QRCODE"}');
        };
        socket.current.onclose = (ev) => {
          setQRCode(null);
          setFailed((f) => f || 'communications');
        };
        socket.current.onmessage = async (ev) => {
          try {
            const json = JSON.parse(ev.data);
            if (json?.qr) {
              setQRCode(json.qr);
            }
            if (json?.token) {
              if ((json.type === 'live' && otherBrand === 'Tracker') ||
                  (json.type === 'uc' && otherBrand === 'Ultracom')) {
                setQRCode(null);
                if (await combineAccounts(json)) {
                  setSuccess(true);
                } else {
                  setFailed('communications');
                }
              } else {
                setFailed('duplicate');
              }
            }
          } catch(ex) {
            console.error('Error parsing JSON:', ex);
          }
        };
      }
    })();
    return () => {
      if (socket.current) {
        socket.current.onopen = null;
        socket.current.onmessage = null;
        socket.current.onclose = null;
        socket.current.close();
      }
    };
  }, [open, otherBrand]);

  return (
    <Dialog open={open} maxWidth='md' fullWidth>
      <DialogTitle>
        <Trans>combine-logins-title</Trans>
      </DialogTitle>
      <DialogContent>
        {
          success ?
            <Typography variant='body1'>
              <Trans>combine-logins-successful</Trans>
            </Typography> :
          failed ?
            <Typography variant='body1'>
              {
                failed === 'duplicate' ?
                t('combine-logins-duplicate', {
                  current: currentBrand,
                  other: otherBrand,
                }) :
                <Trans>combine-logins-failed</Trans>
              }
            </Typography> :
            <Box display='flex' flexDirection='row'>
              <Box className={classes.instructions} flexGrow={1}>
                <Typography variant='body1' gutterBottom>
                  {t('combine-logins-instructions', {
                    current: currentBrand || '',
                    other: otherBrand || '',
                  })}
                </Typography>
                <Typography variant='body1' className={classes.strong} gutterBottom>
                  {t('combine-logins-warning', {
                    current: currentBrand || '',
                    other: otherBrand || '',
                  })}
                </Typography>
                <Typography variant='body1'>
                  {t(currentBrand === 'Ultracom' ? 'login-instructions-1-tr' : 'login-instructions-1-uc')}
                </Typography>
              </Box>
              <Box alignSelf='center'>
                {
                  qrcode ?
                    <img src={qrcode} alt={'QR'}/> :
                  <CircularProgress/>
                }
              </Box>
            </Box>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          if (success) {
            onCompleted();
          } else {
            onClose();
          }
        }}>
          {
            (success || failed) ?
            <Trans>close</Trans> :
            <Trans>cancel</Trans>
          }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CombineAccountsDialog;
