// -*- mode: RJSX; js-indent-level: 2; -*-

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  InputLabel,
  Grid,
  Select,
  MenuItem,
  FormControl,
  Button,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { createNewDesign, newDesign, useDesigns } from '../state/designs';
import { brandSW } from '../util';

const NewDesignNameDialog = ({open, upPath}) => {
  const [naming, setNaming] = useState(false);
  const [nameEdit, setNameEdit] = useState('');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startFrom, setStartFrom] = useState('blank');
  const [copyID, setCopyID] = useState('');
  const [ucid, setUcid] = useState('');
  const [ucErr, setUcErr] = useState(false);
  const designs = useDesigns();

  const handleEnterName = () => {
    setNaming(true);
    (async () => {
      setUcErr(false);
      let source;
      switch (startFrom) {
      case 'copy':
        source = {copy: copyID};
        break;
      case 'ucdr':
        source = {ucdr: ucid};
        break;
      default:
        break;
      }
      const data = await createNewDesign(nameEdit, source);
      setNaming(false);
      if (data) {
        dispatch(newDesign(data));
        navigate('/plan/' + data.id, { replace: true });
      } else if (startFrom === 'ucdr') {
        setUcErr(true);
      }
    })();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Trans>name-new-design</Trans>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('name-new-design-text', {sw: brandSW})}
        </DialogContentText>
        <TextField
            autoFocus
            fullWidth
            label={t('name')}
            type='text'
            disabled={naming}
            value={nameEdit}
            onChange={(ev) => setNameEdit(ev.target.value)}
            onKeyDown={(ev) => {
              if (ev.keyCode === 13) {
                handleEnterName();
              }
            }}/>
        <DialogContentText style={{paddingTop: '2em'}}>
          <Trans>new-design-based-on</Trans>
        </DialogContentText>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <Select
                  fullWidth
                  labelId='based-on-label'
                  disabled={naming}
                  value={startFrom}
                  onChange={(ev) => setStartFrom(ev.target.value)}>
                <MenuItem value='blank'>
                  <Trans>new-blank</Trans>
                </MenuItem>
                {
                  designs?.length &&
                  <MenuItem value='copy'>
                    <Trans>new-copy</Trans>
                  </MenuItem>
                }
                <MenuItem value='ucdr'>
                  <Trans>new-ucdr</Trans>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            {
              startFrom === 'copy' &&
              <FormControl fullWidth >
                <InputLabel id='copy-from-label'>
                  <Trans>as-copy-label</Trans>
                </InputLabel>
                <Select
                    fullWidth
                    labelId='copy-from-label'
                    disabled={naming}
                    value={copyID}
                    onChange={(ev) => setCopyID(ev.target.value)}>
                  {
                    designs?.length &&
                    designs.map((d) => (
                      <MenuItem key={d.id} value={d.id}>
                        {d.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            }
            {
              startFrom === 'ucdr' &&
              <TextField
                  fullWidth
                  error={ucErr}
                  helperText={ucErr ? t('ucdr-invalid') : null}
                  label={t('ucdr-id-label')}
                  type='text'
                  disabled={naming}
                  value={ucid}
                  onChange={(ev) => setUcid(ev.target.value)}
                  onKeyDown={(ev) => {
                    if (ev.keyCode === 13) {
                      handleEnterName();
                    }
                  }}/>
            }
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => navigate(upPath, { replace: true })} color="primary" disabled={naming}>
          <Trans>cancel</Trans>
        </Button>
        <Button
            onClick={handleEnterName}
            color="primary"
            disabled={naming || !nameEdit || (startFrom === 'ucdr' && !ucid) || (startFrom === 'copy' && !copyID)}>
          <Trans>ok</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewDesignNameDialog;
