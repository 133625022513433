// -*- mode: RJSX; js-indent-level: 2; -*-

import { useEffect, useState } from 'react';
import { Grid, Fab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import AppPage from '../common/AppPage';
import DesignCard from './DesignCard';
import { useDesigns, useWorkingOnDesigns, refreshDesigns } from '../state/designs';

const useStyles = makeStyles(() => ({
  container: {
    padding: '2em',
  },
  content: {
    padding: '2em',
  },
  fab: {
    position: 'fixed',
    right: '2em',
    bottom: '2em',
    zIndex: 2000,
  },
}));

const FrontPage = () => {
  const classes = useStyles();
  const designs = useDesigns();
  const working = useWorkingOnDesigns();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sortOrder, setSortOrder] = useState({});

  useEffect(() => {
    if (!designs && !working) {
      dispatch(refreshDesigns());
    }
  }, [designs, working, dispatch]);

  useEffect(() => {
    if (designs) {
      const newOrder = {};
      let updated = false;
      designs.forEach((d) => {
        if (!sortOrder[d.id]) {
          updated = true;
        }
        newOrder[d.id] = d.lastModified;
      });
      if (updated) {
        setSortOrder(newOrder);
      }
    }
  }, [designs, sortOrder]);

  const newDesign = () => {
    navigate('new');
  };

  const sortedDesigns = designs && designs.map((d) => d);
  if (sortedDesigns) {
    sortedDesigns.sort((a, b) =>
                       Date.parse(sortOrder[b.id] ?? b.lastModified) -
                       Date.parse(sortOrder[a.id] ?? a.lastModified));
  }
  return (
    <AppPage>
      <Grid container spacing={2} className={classes.container}>
        {
          !sortedDesigns ?
            <Grid item key='placeholder' xs={12} md={6} lg={4}>
              <DesignCard placeholder/>
            </Grid> :
          sortedDesigns.length === 0 ?
            <Grid item key='none' xs={12} md={6} lg={4}>
              <DesignCard welcome onNewDesign={newDesign}/>
            </Grid> :
          sortedDesigns.map((design) => (
            <Grid item key={design.id} xs={12} md={6} lg={4}>
              <DesignCard design={design}/>
            </Grid>
          ))
        }
      </Grid>
      <div className={classes.fab}>
        <Fab color='secondary' onClick={newDesign}>
          <AddIcon/>
        </Fab>
    </div>
    </AppPage>
  );
};

export default FrontPage;
