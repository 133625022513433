// -*- mode: RJSX; js-indent-level: 2; -*-

import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Checkbox,
  Button,
  CircularProgress,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { editDesign } from '../state/designs';
import { useMyGroups, refreshMyGroups } from '../state/user';
import { brandSW } from '../util';

const ShareDesignDialog = ({design, open, tracking, onClose}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [hidden, setHidden] = useState(false);
  const [toAdd, setToAdd] = useState([]);
  const [toRemove, setToRemove] = useState([]);
  const myGroups = useMyGroups();

  useEffect(() => {
    if (open) {
      setHidden(design.hidden);
      setToAdd([]);
      setToRemove([]);
    }
  }, [open, design.hidden]);

  useEffect(() => {
    if (open && myGroups.shouldRefresh) {
      dispatch(refreshMyGroups());
    }
  }, [open, myGroups.shouldRefresh, dispatch]);

  const oldGroups = (design.shares ?? [])
        .filter((s) => !myGroups.groups.find((m) => m.id === s.id))
        .map((s) => ({...s, old: true}));
  const gsort = (a, b) => {
    if (!a.admin !== !b.admin) {
      return a.admin ? -1 : 1;
    }
    const an = a.name.toLocaleUpperCase();
    const bn = b.name.toLocaleUpperCase();
    return an < bn ? -1 : an === bn ? 0 : 1;
  };
  const my = [...myGroups.groups];
  my.sort(gsort);
  oldGroups.sort(gsort);

  const groups = [
    ...my,
    ...oldGroups,
  ];

  const working = myGroups.refreshing;

  const toggle = (g, checked) => {
    if (checked) {
      const ai = toAdd.indexOf(g.id);
      if (ai >= 0) {
        setToAdd([...toAdd.slice(0, ai), ...toAdd.slice(ai+1)]);
      } else {
        setToRemove([...toRemove, g.id]);
      }
    } else {
      const ri = toRemove.indexOf(g.id);
      if (ri >= 0) {
        setToRemove([...toRemove.slice(0, ri), ...toRemove.slice(ri+1)]);
      } else {
        setToAdd([...toAdd, g.id]);
      }
    }
  };

  return (
    <Dialog open={open} onClose={working ? null : onClose}>
      <DialogTitle>
        {t('share-design-title', {name: design.name})}
      </DialogTitle>
      <DialogContent>
        <List>
          <ListItem button onClick={() => setHidden(!hidden)}>
            <ListItemIcon>
              <Checkbox
                  edge='start'
                  checked={!hidden}
                  onChange={() => setHidden(!hidden)}/>
            </ListItemIcon>
            <ListItemText
                primary={t('share-design-myself')}
                secondary={t('share-design-myself-' + (hidden ? 'off' : 'on'), {
                  sw: brandSW,
                  name: design.name,
                })}/>
          </ListItem>
        </List>
        <Collapse in={!hidden}>
          <Divider/>
          <List>
            {
              !working && !groups.length &&
              <ListItem disabled>
                <ListItemIcon/>
                <ListItemText primary={t('share-design-no-groups')}/>
              </ListItem>
            }
            {
              groups?.map((g) => {
                const checked = toAdd.indexOf(g.id) >= 0 ||
                      (!!design.shares.find((s) => s.id === g.id) && toRemove.indexOf(g.id) < 0);
                const disabled = working || (!checked && !g.admin);
                return (
                  <ListItem key={g.id} button disabled={disabled} onClick={() => toggle(g, checked)}>
                    <ListItemIcon>
                      <Checkbox
                          edge='start'
                          disabled={disabled}
                          checked={checked}
                          onChange={() => toggle(g, checked)} />
                    </ListItemIcon>
                    <ListItemText
                        primary={g.name}
                        secondary={g.old ? t('share-not-member') : !g.admin ? t('share-not-admin') : undefined} />
                  </ListItem>
                );
              })
            }
          </List>
        </Collapse>
      </DialogContent>
      <DialogActions>
        {
          working &&
          <>
            <CircularProgress size={32}/>
            <span style={{flexGrow: 1}}/>
          </>
        }
        <Button
            disabled={working}
            onClick={() => onClose()}>
          <Trans>cancel</Trans>
        </Button>
        <Button
            disabled={working || (hidden === design.hidden && toAdd.length + toRemove.length === 0)}
            onClick={() => {
              dispatch(editDesign({
                tracking,
                design: {
                  id: design.id,
                  hidden: hidden,
                  addShares: toAdd,
                  removeShares: toRemove,
                },
              }));
              onClose();
            }}>
          <Trans>update</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareDesignDialog;
