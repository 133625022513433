// -*- mode: RJSX; js-indent-level: 2; -*-

import { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import {
  Card,
  Box,
  Avatar,
  TextField,
  Typography,
  IconButton,
  CardActions,
  Collapse,
  Popover,
  Switch,
  Menu,
  MenuItem,
  Checkbox,
  CircularProgress,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import FolderIcon from '@mui/icons-material/FolderOpen';
import MenuIcon from '@mui/icons-material/MoreVert';
import VisibleIcon from '@mui/icons-material/Visibility';
import NotVisibleIcon from '@mui/icons-material/VisibilityOff';
import CenterIcon from '@mui/icons-material/CenterFocusStrong';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import TextIcon from '@mui/icons-material/TextFields';
import CloseIcon from '@mui/icons-material/Close';
import LockIcon from '@mui/icons-material/Lock';
import LinkIcon from '@mui/icons-material/Link';
import ColorChooser from '../common/ColorChooser';
import StyleChooser from '../common/StyleChooser';
import OpacityChooser from '../common/OpacityChooser';
import SizeChooser from '../common/SizeChooser';
import TargetIcon, {
  defaultColors,
  stylesForGeometryType,
  subStylesForStyle,
  opacitySupportedForStyle,
  sizeSettingsForMarkup,
  typeForGeometryType,
} from '../icons/TargetStyles';
import { Omariista as OmariistaIcon } from '../icons/ButtonIcons';
import { Mml as MMLIcon, Riistakeskus as RiistakeskusIcon, Lm as LMIcon } from '../icons/TrapmapIcons';
import { updateLatestColors } from '../state/designs';
import {
  editMarkup,
  refreshMarkup,
  deleteMarkup,
  resurrectMarkup,
  deletePlaceholder,
  canEditMarkup,
  markerLabel,
  useMarkup,
  useWorking,
  nextZ,
} from '../state/markup';
import {
  requestFocus,
  updateMarkupDefaults,
  useHighlightedSubMarker,
  highlightSubMarker,
  editAdding,
  dismissAdding,
  showFeedbackSnack,
} from '../state/ui';
import { withoutAlpha, justAlpha, colorAsRGBA, textForBackground } from '../util';
import { useUndo } from './Undo';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: '6px',
    border: '4px solid transparent',
    transition: theme.transitions.create('border-color', {
      duration: theme.transitions.duration.shortest,
    })
  },
  mainIcon: {
    color: theme.palette.primary.main,
  },
  mainIconButton: {
    color: `#222 !important`,
  },
  header: {
    width: '100%',
    textAlign: 'start',
  },
  row: {
    paddingTop: '0.25em',
    paddingBottom: '0.25em',
    display: 'flex',
    flexDirection: 'row',
  },
  firstRow: {
    paddingTop: 0,
  },
  lastRow: {
    paddingBottom: 0,
  },
  title: {
    paddingLeft: '0.5em',
    paddingRight: '0.5em',
    overflow: 'hidden',
  },
  titleButton: {
    width: '100%',
    height: '100%',
    textAlign: 'start',
    position: 'absolute',
    left: 0,
  },
  avatarSpacer: {
    width: 'calc(48px + 0.5em)',
  },
  nameSpacer: {
    width: 'calc(48px + 2em)',
  },
  buttonAvatar: {
    width: '24px',
    height: '24px',
    transform: 'scale(1.25)',
  },
  menuButton: {
    marginLeft: 'auto !important',
  },
  scaledIcon: {
    transform: 'scale(1.5)',
  },
  opacityText: {
    fontSize: '70%',
    transform: 'translateY(1px)',
  },
  childContainer: {
    marginLeft: '-0.5em',
    marginRight: '-0.5em',
    marginBottom: '-1em',
  },
  child: {
    marginBottom: '1em',
  },
}));

const SpecificNameEditor = ({markup, index, tracking, readOnly}) => {
  const el = useRef(null);
  const names = markup.properties.names.names ?? [];
  const [renaming, setRenaming] = useState(null);
  const dispatch = useDispatch();
  const hilight = useHighlightedSubMarker();
  const [timedHiOff, setTimedHiOff] = useState(false);
  const [setUndo] = useUndo();
  const { t } = useTranslation();

  useEffect(() => {
    if (el.current && hilight?.markup === markup.properties.id && hilight?.index === index) {
      el.current.focus();
    }
  }, [hilight, markup, index]);

  useEffect(() => {
    if (timedHiOff) {
      const now = Date.now();
      if (now >= timedHiOff) {
        setTimedHiOff(false);
        if (hilight?.markup === markup.properties.id && hilight?.index === index) {
          dispatch(highlightSubMarker(null));
        }
        return undefined;
      }
      const t = setTimeout(() => {
        if (timedHiOff) {
          setTimedHiOff(timedHiOff-1);
        }
      }, timedHiOff - now);
      return () => clearTimeout(t);
    }
    return undefined;
  }, [timedHiOff, hilight, dispatch, markup, index]);

  const prefix = markup.properties.name ?? '';
  return (
    <TextField
        inputRef={el}
        value={renaming !== null ? renaming : names[index] ?? ''}
        placeholder={prefix.length < 2 ? `${prefix}${index+1}` : `${prefix} ${index+1}`}
        onChange={(ev) => setRenaming(ev.target.value)}
        InputProps={{readOnly: readOnly}}
        onKeyDown={(ev) => {
          if (ev.keyCode === 13) {
            ev.target.blur();
          }
        }}
        onFocus={(ev) => {
          dispatch(highlightSubMarker(markup.properties.id, index));
        }}
        onBlur={(ev) => {
          if (renaming !== null && renaming !== names[index]) {
            const prefix = names.slice(0, index);
            while (prefix.length < index) {
              prefix.push('');
            }
            setUndo(t('undo-rename-markup'),
                    editMarkup({markup, tracking, names: {
                      enabled: true,
                      names: [
                        ...prefix,
                        renaming,
                        ...names.slice(index+1),
                      ],
                    }}),
                    editMarkup({markup, tracking, names: markup.properties.names}),
                    dispatch);
            setRenaming(null);
          }
          setTimedHiOff(Date.now() + 300);
        }}/>
  );
};

const markupIcon = (markup, classes) => {
  const [mainStyle] = (markup.properties.style || '').split('/');
  switch (markup.type) {
  case 'Feature':
    const color = withoutAlpha(markup.properties.color);
    if (mainStyle === 'none') {
      return <TextIcon style={{color: color}}/>;
    }
    return <TargetIcon
               id={mainStyle}
               color={color}
               className={classes.scaledIcon}/>;

  case 'OmaRiista':
    return <OmariistaIcon color={'black'} className={classes.scaledIcon}/>;
      
  case 'FinStateHunting':
    return <RiistakeskusIcon color={'black'} className={classes.scaledIcon} />;

  case 'FinProperty':
    return <MMLIcon color={'black'} className={classes.scaledIcon} />;

  case 'SweProperty':
    return <LMIcon color={'black'} className={classes.scaledIcon} />;

  case 'Folder':
    return <FolderIcon className={clsx(classes.mainIcon, classes.scaledIcon)}/>;

  default:
    console.warn('Unknown markup type', markup.type);
    return <Avatar className={classes.buttonAvatar}>&nbsp;</Avatar>;
  }
};

const markupSubtitle = (markup, t) => {
  if (!markup.geometry?.coordinates) {
    return t('new-markup');
  } else if (markup.geometry.type === 'Point') {
    return t('point-single');
  } else if (markup.geometry.type === 'MultiPoint') {
    return t('point-multi', {count: markup.geometry.coordinates.length});
  } else if (markup.type === 'OmaRiista') {
    return markup.properties.extRef;
  }
  return undefined;
};

export const SimpleMarkupItemCard = ({design, markup, multiSelect, setMultiSelect, map, tracking}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [longPressDown, setLongPressDown] = useState(0);
  const parent = useMarkup(design.id, markup?.properties?.linkedTo ?? 'n/a');
  const { t } = useTranslation();

  const icon = markupIcon(markup, classes);
  const sub = markupSubtitle(markup, t);

  useEffect(() => {
    if (longPressDown) {
      const timer = setTimeout(() => {
        setLongPressDown(0);
        setMultiSelect({[markup.properties.id]: true});
      }, 500);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [longPressDown, multiSelect, setMultiSelect, markup]);

  return (
    <Card
        elevation={4}
        className={clsx(classes.card)}
        onMouseDown={multiSelect ? null : () => setLongPressDown(Date.now())}
        onMouseUp={multiSelect ? null : () => setLongPressDown(0)}
        onMouseOut={multiSelect ? null : () => setLongPressDown(0)}>
      <Box className={clsx(classes.row, classes.firstRow, classes.header)}>
        <Box alignSelf='center'>
          <IconButton
              className={classes.mainIconButton}
              onClick={() => {
                if (!markup.properties.visible) {
                  dispatch(editMarkup({markup, tracking, visible: true}));
                }
                map?.flyToBounds([[markup.bbox[1], markup.bbox[0]], [markup.bbox[3], markup.bbox[2]]]);
              }}>
            <div style={{position: 'relative'}}>
              {icon}
              {
                !markup.properties.visible &&
                  <div
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                        transform: 'scale(2)'}}>
                    <NotInterestedIcon/>
                  </div>
              }
              {
                !!parent &&
                  <div
                    style={{
                      position: 'absolute',
                      top: '75%',
                      left: '75%',
                      marginTop: '-6px',
                      marginLeft: '-6px',
                      transform: 'scale(0.75)'}}>
                    <LinkIcon/>
                  </div>
              }
            </div>
          </IconButton>
        </Box>
        <Box alignSelf='center' flexGrow={1} className={classes.title}>
          <Typography variant='body1' style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
            {markup.properties.name ?? `(${t('no-name')})`}
          </Typography>
          {sub && <Typography variant='body2'>{sub}</Typography>}
        </Box>
        <Box alignSelf='center'>
          {
            multiSelect ? (
              <Checkbox
                  checked={!!multiSelect[markup.properties.id]}
                  onChange={() => setMultiSelect({
                    ...multiSelect,
                    [markup.properties.id]: !multiSelect[markup.properties.id],
                  })} />
            ) : (
              <IconButton onClick={() => dispatch(requestFocus(markup, {edit: canEditMarkup(markup), flyTo: true}))}>
                <EditIcon/>
              </IconButton>
            )
          }
        </Box>
      </Box>
    </Card>
  );
};

const MarkupItemCard = ({
  className,
  design,
  map,
  markup,
  onClose,
  nameEditRef,
  tracking,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mid = markup.properties.id;
  const [colorChooserAnchor, setColorChooserAnchor] = useState(null);
  const [styleChooserAnchor, setStyleChooserAnchor] = useState(null);
  const [subStyleChooserAnchor, setSubStyleChooserAnchor] = useState(null);
  const [sizeChooserAnchor, setSizeChooserAnchor] = useState(null);
  const [opacityChooserAnchor, setOpacityChooserAnchor] = useState(null);
  const [renaming, setRenaming] = useState(null);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [setUndo, clearUndo] = useUndo();
  const parent = useMarkup(design.id, markup?.properties?.linkedTo ?? 'n/a');
  const allMarkup = useMarkup(design.id);
  const working = useWorking(design.id);

  const [mainStyle, subStyle] = (markup.properties.style || '').split('/');

  const menu = [];
  const icon = markupIcon(markup, classes);
  const subtitle = markupSubtitle(markup, t);
  let hasStyle, styleIcon, hasVisibility, type, maybeMultipleNames, allowNoneStyle;
  switch (markup.type) {
  case 'Feature':
    hasStyle = true;
    styleIcon = icon;
    hasVisibility = true;
    type = typeForGeometryType(markup);
    allowNoneStyle = type === 'poi' && t('point-only-text');
    maybeMultipleNames = markup.geometry?.type === 'MultiPoint' && markup.geometry.coordinates.length > 1;
    break;

  case 'OmaRiista':
    menu.push({
      title: t('oma-riista-update'),
      fn: () => {
        setMenuAnchor(null);
        setUndo(t('oma-riista-update'),
                refreshMarkup(markup, tracking, nextZ(design.contents.point, 'point', false)),
                editMarkup({markup, tracking, geometry: markup.geometry}),
                dispatch);
      },
    });
    hasStyle = true;
    styleIcon = <TargetIcon
                    id={mainStyle}
                    color={withoutAlpha(markup.properties.color)}
                    className={classes.scaledIcon}/>;
    hasVisibility = true;
    type = typeForGeometryType(markup);
    break;
      
  case 'FinStateHunting':
    menu.push({
      title: t('state-hunting-fi-update'),
      fn: () => {
        setMenuAnchor(null);
        setUndo(t('state-hunting-fi-update'),
                refreshMarkup(markup, tracking),
                editMarkup({markup, tracking, geometry: markup.geometry}),
                dispatch);
      },
    });
    hasStyle = true;
    styleIcon = <TargetIcon
                    id={mainStyle}
                    color={withoutAlpha(markup.properties.color)}
                    className={classes.scaledIcon}/>;
    hasVisibility = true;
    type = typeForGeometryType(markup);
    break;

  case 'FinProperty':
  case 'SweProperty':
    hasStyle = true;
    styleIcon = <TargetIcon
                    id={mainStyle}
                    color={withoutAlpha(markup.properties.color)}
                    className={classes.scaledIcon}/>;
    hasVisibility = true;
    type = typeForGeometryType(markup);
    break;

  case 'Folder':
    hasStyle = false;
    hasVisibility = false;
    break;

  default:
    console.warn('Unknown markup type', markup.type);
    break;
  }
  const sizeSettings = sizeSettingsForMarkup(markup);

  const edit = (undo, fwd, bwd) => {
    if (mid === 'new') {
      setUndo(t(undo), editAdding(fwd), editAdding(bwd), dispatch);
    } else {
      setUndo(t(undo), editMarkup({markup, tracking, ...fwd}), editMarkup({markup, ...bwd}), dispatch);
    }
  };

  return (
    <>
      <Card
          elevation={4}
          className={clsx(className, classes.card)}
          {...props}>
        <Box className={clsx(classes.row, classes.firstRow, classes.header)}>
          <Box alignSelf='center'>
            <IconButton
                className={classes.mainIconButton}
                onClick={() => {
                  map?.flyToBounds([[markup.bbox[1], markup.bbox[0]], [markup.bbox[3], markup.bbox[2]]]);
                }}>
              <div style={{position: 'relative'}}>
                {icon}
                {
                  !markup.properties.visible && hasVisibility &&
                    <div
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: '-12px',
                          marginLeft: '-12px',
                          transform: 'scale(2)'}}>
                      <NotInterestedIcon/>
                    </div>
                }
                {
                  !!parent &&
                    <div
                      style={{
                        position: 'absolute',
                        top: '75%',
                        left: '75%',
                        marginTop: '-6px',
                        marginLeft: '-6px',
                        transform: 'scale(0.75)'}}>
                      <LinkIcon/>
                    </div>
                }
                {
                  working &&
                    <CircularProgress style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      marginTop: '-8px',
                      marginLeft: '-5px',
                    }} />
                }
              </div>
            </IconButton>
          </Box>
          <Box alignSelf='center' flexGrow={1} className={classes.title}>
            <TextField
                inputRef={nameEditRef}
                fullWidth
                autoFocus
                variant='standard'
                InputProps={{readOnly: !!parent}}
                label={subtitle ?? t('name')}
                value={renaming !== null ? renaming : markup.properties.name ?? ''}
                onChange={(ev) => setRenaming(ev.target.value)}
                onBlur={() => {
                  if (renaming === null) {
                    return;
                  }
                  if (renaming !== markup.properties.name) {
                    edit('undo-rename-markup', {name: renaming}, {name: markup.properties.name});
                  }
                  setRenaming(null);
                }}
                onKeyDown={(ev) => {
                  if (ev.keyCode === 13) {
                    ev.target.blur();
                  }
                  if (ev.keyCode === 27) {
                    if (typeof(mid) === 'string' && mid === 'new') {
                      // New markup, delete
                      dispatch(deletePlaceholder(markup));
                    } else {
                      setRenaming(null);
                      setTimeout(() => ev.target.blur(), 1);
                    }
                  }
                }}/>
          </Box>
          <Box alignSelf='center'>
            <IconButton onClick={() => onClose()}>
              <CloseIcon/>
            </IconButton>
          </Box>
        </Box>
        {
          maybeMultipleNames &&
            <Box className={classes.row}>
              <Box className={classes.avatarSpacer}/>
              <Box alignSelf='center' flexGrow={1}>
                <Typography variant='body1'>
                  <Trans>specific-names</Trans>
                </Typography>
              </Box>
              <Box alignSelf='center'>
                <Switch
                    color='primary'
                    checked={markup.properties.names?.enabled ?? false}
                    disabled={!!parent}
                    onChange={() => {
                      setUndo(t('undo-specific-names'), editMarkup({markup, tracking, names: {
                        names: markup.properties.names?.enabled ?
                          [] :
                          markup.geometry.coordinates.map((_, i) => markerLabel(markup, i)),
                        enabled: !markup.properties.names?.enabled,
                      }}), editMarkup({markup, tracking, names: {
                        names: markup.properties.name?.names ?? [],
                        enabled: markup.properties.names?.enabled,
                      }}), dispatch);
                    }}/>
              </Box>
            </Box>
        }
        {
          maybeMultipleNames && markup.properties.names?.enabled &&
            markup.geometry.coordinates.map((_, i) => (
              <Box key={`name/${markup.properties.id}.${i}`} className={classes.row}>
                <Box className={classes.nameSpacer}/>
                <SpecificNameEditor markup={markup} index={i} tracking={tracking} readOnly={!!parent}/>
              </Box>
            ))
        }
        {
          hasStyle &&
            <>
              <Box className={classes.row}>
                <Box className={classes.avatarSpacer}/>
                <Box alignSelf='center' flexGrow={1}>
                  <Typography variant='body1'>
                    <Trans>color</Trans>
                  </Typography>
                </Box>
                <Box alignSelf='center'>
                  <IconButton onClick={(ev) => setColorChooserAnchor(ev.target)}>
                    <Avatar
                        style={{backgroundColor: withoutAlpha(markup.properties.color)}}
                        classes={{root: classes.buttonAvatar}}>
                      &nbsp;
                    </Avatar>
                  </IconButton>
                </Box>
              </Box>
              <Box className={classes.row}>
                <Box className={classes.avatarSpacer}/>
                <Box alignSelf='center' flexGrow={1}>
                  <Typography variant='body1'>
                    <Trans>style</Trans>
                  </Typography>
                </Box>
                <Box alignSelf='center'>
                  <IconButton onClick={(ev) => setStyleChooserAnchor(ev.target)}>
                    {styleIcon}
                  </IconButton>
                </Box>
              </Box>
              <Collapse in={!!subStylesForStyle[mainStyle]}>
                <Box className={classes.row}>
                  <Box className={classes.avatarSpacer}/>
                  <Box alignSelf='center' flexGrow={1}>
                    <Typography variant='body1'>
                      <Trans>shading-style</Trans>
                    </Typography>
                  </Box>
                  <Box alignSelf='center'>
                    <IconButton onClick={(ev) => setSubStyleChooserAnchor(ev.target)}>
                      <TargetIcon
                          id={subStyle ?? (subStylesForStyle[mainStyle] && subStylesForStyle[mainStyle][0])}
                          color={withoutAlpha(markup.properties.color)}
                          className={classes.scaledIcon}/>
                    </IconButton>
                  </Box>
                </Box>
              </Collapse>
              {
                type === 'line' &&
                  <Box className={classes.row}>
                    <Box className={classes.avatarSpacer}/>
                    <Box alignSelf='center' flexGrow={1}>
                      <Typography variant='body1'>
                        <Trans>line-arrow</Trans>
                      </Typography>
                    </Box>
                    <Box alignSelf='center'>
                      <Switch
                          color='primary'
                          checked={subStyle === 'arrow'}
                          onChange={() => {
                            const ns = subStyle === 'arrow' ? mainStyle : mainStyle + '/arrow';
                            const os = subStyle === 'arrow' ? mainStyle + '/arrow' : mainStyle;
                            edit('undo-arrow', {style: ns}, {style: os});
                          }}/>
                    </Box>
                  </Box>
              }
              <Collapse in={!!sizeSettings}>
                <Box className={classes.row}>
                  <Box className={classes.avatarSpacer}/>
                  <Box alignSelf='center' flexGrow={1}>
                    <Typography variant='body1'>
                      {t(sizeSettings?.text)}
                    </Typography>
                  </Box>
                  <Box alignSelf='center'>
                    <IconButton onClick={(ev) => setSizeChooserAnchor(ev.target)}>
                      <Avatar
                          style={{backgroundColor: withoutAlpha(markup.properties.color)}}
                          classes={{root: classes.buttonAvatar}}>
                        <span
                            className={classes.opacityText}
                            style={{color: textForBackground(withoutAlpha(markup.properties.color))}}>
                          {markup.properties.size ?? sizeSettings?.min}
                        </span>
                      </Avatar>
                    </IconButton>
                  </Box>
                </Box>
              </Collapse>
              <Collapse in={!!sizeSettings?.scaling}>
                <Box className={classes.row}>
                  <Box className={classes.avatarSpacer}/>
                  <Box alignSelf='center' flexGrow={1}>
                    <Typography variant='body1'>
                      <Trans>scaling</Trans>
                    </Typography>
                  </Box>
                  <Box alignSelf='center'>
                    <Switch
                        color='primary'
                        checked={subStyle !== 'nonscaled'}
                        onChange={() => {
                          const ns = subStyle === 'nonscaled' ? mainStyle : mainStyle + '/nonscaled';
                          const os = subStyle === 'nonscaled' ? mainStyle + '/nonscaled' : mainStyle;
                          edit('undo-scaling', {style: ns}, {style: os});
                        }}/>
                  </Box>
                </Box>
              </Collapse>
              <Collapse in={opacitySupportedForStyle[mainStyle]}>
                <Box className={classes.row}>
                  <Box className={classes.avatarSpacer}/>
                  <Box alignSelf='center' flexGrow={1}>
                    <Typography variant='body1'>
                      <Trans>shading-opacity</Trans>
                    </Typography>
                  </Box>
                  <Box alignSelf='center'>
                    <IconButton onClick={(ev) => setOpacityChooserAnchor(ev.target)}>
                      <Avatar
                          style={{backgroundColor: colorAsRGBA(markup.properties.color)}}
                          classes={{root: classes.buttonAvatar}}>
                        <span
                            className={classes.opacityText}
                            style={{color: textForBackground(markup.properties.color)}}>
                          {(() => {
                            const i = Math.round(justAlpha(markup.properties.color) * 100);
                            return i > 99 ? '∞' : i.toString();
                          })()}
                        </span>
                      </Avatar>
                    </IconButton>
                  </Box>
                </Box>
              </Collapse>
            </>
        }
        {
          false && // Descriptions disabled for now
            <Box className={clsx(classes.row, classes.lastRow)}>
              <Box className={classes.avatarSpacer}/>
              <Box flexGrow={1}>
                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    variant='outlined'
                    placeholder={t('description')}
                    value={markup.properties.description ?? ''}/>
              </Box>
            </Box>
        }
        <CardActions>
          {!parent &&
           <IconButton
             onClick={() => {
               if (mid === 'new') {
                 dispatch(dismissAdding());
                 clearUndo();
               } else {
                 const delList = [markup];
                 for (const id in allMarkup.lookup) {
                   const m = allMarkup.lookup[id];
                   if (m.properties?.linkedTo === markup.properties.id) {
                     delList.push(m);
                   }
                 }
                 setUndo(t('undo-delete-markup'),
                         delList.map((m) => deleteMarkup(m, tracking)),
                         delList.map((m) => resurrectMarkup(m, tracking)),
                         dispatch,
                         t('undo-hint-deleted', {name: markup.properties.name || `(${t('no-name')})`}));
               }
             }}>
             <DeleteIcon/>
           </IconButton>}
          {!!parent &&
           <IconButton onClick={() => {
             dispatch(showFeedbackSnack('error', t('associated-with', {main: parent.properties.name ?? '…'})));
           }}>
             <LockIcon/>
           </IconButton>}
          <IconButton onClick={() => {
            edit('undo-visibility',
                 {visible: !markup.properties.visible},
                 {visible: markup.properties.visible});
          }}>
            {markup.properties.visible ? <VisibleIcon/> : <NotVisibleIcon/>}
          </IconButton>
          <IconButton
              disabled={!markup.geometry?.coordinates}
              onClick={() => {
                map?.flyToBounds([[markup.bbox[1], markup.bbox[0]], [markup.bbox[3], markup.bbox[2]]]);
              }}>
            <CenterIcon/>
          </IconButton>
          <Box flexGrow={1}/>
          {
            !menu.length ? null :
              <IconButton className={classes.menuButton} onClick={(ev) => setMenuAnchor(ev.currentTarget)}>
                <MenuIcon/>
              </IconButton>
          }
        </CardActions>
      </Card>
      {
        hasStyle &&
          <>
            <Popover
                anchorEl={colorChooserAnchor}
                open={!!colorChooserAnchor}
                onClose={() => setColorChooserAnchor(null)}>
              <ColorChooser
                  value={markup.properties.color}
                  palette={[...defaultColors, ...(design.latestColors ?? []).map((c) => c.color)]}
                  onChange={(color) => {
                    setColorChooserAnchor(null);
                    if (color !== markup.properties.color) {
                      edit('undo-color', {color: color}, {color: markup.properties.color});
                      updateLatestColors(design, withoutAlpha(color).toLowerCase(), dispatch);
                      if (type) {
                        dispatch(updateMarkupDefaults(type, {color: color}));
                      }
                    }
                  }}/>
            </Popover>
            <Popover
                anchorEl={styleChooserAnchor}
                open={!!styleChooserAnchor}
                onClose={() => setStyleChooserAnchor(null)}>
              <StyleChooser
                  value={mainStyle}
                  styles={stylesForGeometryType[markup.geometry.type]}
                  none={allowNoneStyle}
                  color={withoutAlpha(markup.properties.color)}
                  onChange={(style) => {
                    setStyleChooserAnchor(null);
                    if (style !== mainStyle) {
                      const ns = subStyle ? `${style}/${subStyle}` : style;
                      const os = subStyle ? `${mainStyle}/${subStyle}` : mainStyle;
                      edit('undo-style', {style: ns}, {style: os});
                      if (type) {
                        dispatch(updateMarkupDefaults(type, {style: ns}));
                      }
                    }
                  }}/>
            </Popover>
            <Popover
                anchorEl={subStyleChooserAnchor}
                open={!!subStyleChooserAnchor}
                onClose={() => setSubStyleChooserAnchor(null)}>
              <StyleChooser
                  value={subStyle}
                  styles={subStylesForStyle[mainStyle] ?? []}
                  color={withoutAlpha(markup.properties.color)}
                  onChange={(style) => {
                    setSubStyleChooserAnchor(null);
                    if (style !== subStyle) {
                      const ns = style ? `${mainStyle}/${style}` : mainStyle;
                      const os = subStyle ? `${mainStyle}/${subStyle}` : mainStyle;
                      edit('undo-style', {style: ns}, {style: os});
                      if (type) {
                        dispatch(updateMarkupDefaults(type, {style: ns}));
                      }
                    }
                  }}/>
            </Popover>
            {
              sizeSettings &&
                <Popover
                    anchorEl={sizeChooserAnchor}
                    open={!!sizeChooserAnchor}
                    onClose={() => setSizeChooserAnchor(null)}>
                  <SizeChooser
                      value={markup.properties.size ?? sizeSettings.min}
                      min={sizeSettings.min}
                      max={sizeSettings.max}
                      step={sizeSettings.step}
                      color={withoutAlpha(markup.properties.color)}
                      onChange={(value) => {
                        setSizeChooserAnchor(null);
                        if (value !== markup.properties.size) {
                          edit('undo-size', {size: value}, {size: markup.properties.size});
                        }
                      }}/>
                </Popover>
            }
            <Popover
                anchorEl={opacityChooserAnchor}
                open={!!opacityChooserAnchor}
                onClose={() => setOpacityChooserAnchor(null)}>
              <OpacityChooser
                  value={justAlpha(markup.properties.color)}
                  color={withoutAlpha(markup.properties.color)}
                  onChange={(value) => {
                    setOpacityChooserAnchor(null);
                    if (value !== justAlpha(markup.properties.color)) {
                      const c = '#' +
                            ('0' + Math.round(value * 255).toString(16)).slice(-2) +
                            withoutAlpha(markup.properties.color).slice(1);
                      edit('undo-opacity', {color: c}, {color: markup.properties.color});
                      if (type) {
                        dispatch(updateMarkupDefaults(type, {color: c}));
                      }
                    }
                  }}/>
            </Popover>
          </>
      }
      <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClose={() => setMenuAnchor(null)}>
        {
          menu.map((item, i) => (
            <MenuItem key={'' + i} onClick={item.fn}>{item.title}</MenuItem>
          ))
        }
      </Menu>
    </>
  );
};

export default MarkupItemCard;
