// -*- mode: RJSX; js-indent-level: 2; -*-

import { createSlice, createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { createApiAsyncThunk, apiRequest } from './api';

export const useUser = () => useSelector((state) => state.user);

const noGroups = [];
export const useMyGroups = () => useSelector(createSelector(
  ({user}) => user.myGroups?.groups ?? noGroups,
  ({user}) => user.myGroups?.refreshing ?? false,
  ({user}) => !user.myGroups?.refreshing && (!user.myGroups?.lastRefresh || user.myGroups?.lastRefresh < Date.now() - 600000),
  (groups, refreshing, shouldRefresh) => {
    return {groups, refreshing, shouldRefresh};
  }));

export const authVerify = createApiAsyncThunk('user/verify', null, {
  url: '/api/v1/verify',
  verify: (resp) => resp.data.status === 'SUCCESS',
  ok: ({status, ...out}) => out,
});

export const requestLogout = createApiAsyncThunk('logout', null, {url: '/api/v1/logout'});
export const logout = requestLogout.fulfilled;

const setToken = (data) => {
  window.localStorage.setItem('authToken', data.token);
  return {
    authToken: data.token,
    authChecked: true,
    displayName: data.displayname,
    uniqueName: data.uniquename,
  };
};

const clearToken = () => {
  window.localStorage.removeItem('authToken');
  return {
    authToken: null,
    authChecked: false,
    displayName: undefined,
    uniqueName: undefined,
  };
};

export const startQRLogin = () => apiRequest({
  url: '/api/v1/qrcode',
  ok: ({url}) => new WebSocket(url),
});

export const combineAccounts = (data) => apiRequest({
  method: 'post',
  url: '/api/v1/user/combine',
  input: {token: data.token},
  verify: (resp) => resp.data?.status === 'SUCCESS',
});

export const refreshMyGroups = createApiAsyncThunk('user/mygroups', null, {
  url: '/api/v1/user/groups',
  verify: (resp) => resp.data?.status === 'SUCCESS',
  ok: ({groups}) => groups,
});

export const listMyLogins = () => apiRequest({
  url: '/api/v1/user/logins',
  ok: ({logins}) => logins,
});

export const deleteLogin = (id) => apiRequest({
  method: 'delete',
  url: `/api/v1/user/logins/${id}`,
});

export const resolveUser = (who) => apiRequest({
  method: 'post',
  url: '/api/v1/user/resolve',
  input: {who},
  ok: ({id}) => id,
});

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    authToken: window.localStorage.getItem('authToken'),
    authChecked: false,
    displayName: null,
    uniqueName: null,
    myGroups: {},
  },
  reducers: {
    successfulLogin: (state, action) => ({
      ...state,
      ...setToken(action.payload),
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(authVerify.fulfilled, (state, action) =>
               action.payload ? {
                 ...state,
                 authChecked: true,
                 displayName: action.payload.displayname,
                 uniqueName: action.payload.uniquename,
                 admin: action.payload.admin || false,
                 brands: action.payload.brands || [],
               } : {
                 ...state,
                 ...clearToken(),
               })
      .addCase(refreshMyGroups.pending, (state, action) => ({
        ...state,
        myGroups: {
          ...state.myGroups,
          refreshing: true,
        },
      }))
      .addCase(refreshMyGroups.fulfilled, (state, action) => ({
        ...state,
        myGroups: {
          groups: action.payload ?? [],
          lastRefresh: Date.now(),
          refreshing: false,
        },
      }))
      .addCase(logout, (state, action) => clearToken())
      .addDefaultCase((state, action) => {});
  }
});

export const { successfulLogin } = userSlice.actions;
export default userSlice.reducer;
