// -*- mode: RJSX; js-indent-level: 2; -*-

import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { MapContainer, TileLayer, WMSTileLayer, useMap } from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import ZoomControl from './ZoomControl';
import LayersControl from './LayersControl';
import LayerDialog from './LayerDialog';
import { useMapLayers, refreshMapList } from '../state/layers';

const findOverlaySpec = (id, list) => {
  for (let i = 0; i < list.length; i++) {
    if (list[i].id === id) {
      return list[i];
    }
    if (list[i].flavors) {
      const child = findOverlaySpec(id, list[i].flavors);
      if (child) {
        return child;
      }
    }
  }
  return null;
};

const parseWMSUrl = (url) => {
  const q = url.indexOf('?');
  if (q < 0) {
    return null;
  }
  const fields = {};
  let upcase = false;
  url.substring(q+1).split('&').forEach((pair) => {
    const kv = pair.split('=');
    if (kv.length === 2) {
      fields[kv[0].toLowerCase()] = decodeURIComponent(kv[1]);
      if (kv[0] === 'SERVICE') {
        upcase = true;
      }
    }
  });
  if (fields['service'] !== 'WMS' || !fields['layers']) {
    return null;
  }
  const args = {
    layers: fields['layers'],
    uppercase: upcase,
  };
  ['styles', 'format', 'transparent', 'version', 'crs'].forEach((k) => {
    if (fields[k] !== undefined) {
      args[k] = fields[k];
    }
  });
  return [url.substring(0, q), args];
};

const MapView = ({
  forwardRef,
  whenCreated,
  children,
  layers,
  setActiveBaseMap,
  setActiveOverlays,
  ...props
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const map = useRef(null);
  const [mapLayers, needRefresh] = useMapLayers(t('lang-id'));
  const [layersDialog, setLayersDialog] = useState(false);

  useEffect(() => {
    if (needRefresh) {
      dispatch(refreshMapList(t('lang-id')));
    }
  }, [needRefresh, dispatch, t]);

  const baseIndex = mapLayers?.base_maps ?
        Math.max(mapLayers.base_maps.findIndex((f) => f.id === layers.baseMapId), 0) :
        0;
  const overSpecs = mapLayers?.overlay_maps ?
        layers.overlayIds.map((id) => findOverlaySpec(id, mapLayers.overlay_maps)).filter((spec) => spec?.url) :
        [];

  return (
    <>
      <MapContainer
          maxZoom={20}
          zoomControl={false}
          doubleClickZoom={false}
          {...props}>
        <MapViewReferencer
            forwardRef={forwardRef}
            whenCreated={whenCreated} />
        {
          mapLayers &&
          <>
            <TileLayer
                key={mapLayers.base_maps[baseIndex].id}
                zIndex={0}
                url={mapLayers.base_maps[baseIndex].url}
                minZoom={0}
                maxZoom={20}/>
            {overSpecs.map((spec, i) => {
              const [wmsurl, wmsargs] = parseWMSUrl(spec.url) ?? [];
              return wmsurl ? (
                <WMSTileLayer
                    key={spec.id}
                    zIndex={i+1}
                    minZoom={spec.min_z || 0}
                    maxZoom={spec.max_z || 20}
                    url={wmsurl}
                    params={wmsargs}/>
              ) : (
                <TileLayer
                    key={spec.id}
                    zIndex={i+1}
                    url={spec.url}
                    minZoom={spec.min_z || 0}
                    maxZoom={spec.max_z || 20}/>
              );
            })}
            <ZoomControl/>
            <LayersControl onClick={() => setLayersDialog(true)}/>
          </>
        }
        {children}
      </MapContainer>
      {
        mapLayers &&
          <LayerDialog
              onClose={() => setLayersDialog(false)}
              open={layersDialog}
              map={map.current}
              mapList={mapLayers}
              layers={layers}
              setActiveBaseMap={setActiveBaseMap}
              setActiveOverlays={setActiveOverlays} />
      }
    </>
  );
};

const MapViewReferencer = ({forwardRef, whenCreated}) => {
  const map = useMap();
  const [created, setCreated] = useState(false);

  useEffect(() => {
    if (forwardRef) {
      forwardRef.current = map;
    }
    if (!created && map && whenCreated) {
      setCreated(true);
      whenCreated(map);
    }
  }, [forwardRef, whenCreated, map, created]);
};

export default MapView;
