// -*- mode: RJSX; js-indent-level: 2; -*-

import { useEffect, useState, useRef, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  CircularProgress,
  Tabs,
  Tab,
  Fab,
  Snackbar,
  Button,
  IconButton,
  Fade,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Zoom,
  Backdrop,
  Card,
  Alert,
} from '@mui/material';
import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import CloseIcon from '@mui/icons-material/Close';
import AppPage from '../common/AppPage';
import MapView from '../map/MapView';
import NewDesignNameDialog from './NewDesignNameDialog';
import DesignSidebarHeader from './DesignSidebarHeader';
import MapEditor, { mapEditOptions } from './MapEditor';
import BackgroundMarkup from './BackgroundMarkup';
import AreaMarkup from './AreaMarkup';
import PointMarkup from './PointMarkup';
import DrawerTabPanel from './DrawerTabPanel';
import Preview from '../common/Preview';
import ImportDialog from './ImportDialog';
import ShareEditDialog from '../common/ShareEditDialog';
import MarkupItemCard from './MarkupItemCard';
import { UndoContext } from './Undo';
import { registerMapPatterns, MapPatternContext } from '../icons/TargetStyles';
import {
  useDesign,
  useDesigns,
  useWorkingOnDesigns,
  refreshDesigns,
  trackDesignChanges,
  designDeleted,
  designUpdated,
  redeemEditorToken,
  designRedeemed,
} from '../state/designs';
import { useMarkup } from '../state/markup';
import { authToken } from '../state/api';
import {
  refreshDesignContents,
  invalidateDesignContents,
  reorderMarkup,
  isBackgroundMarkup,
  isAreaMarkup,
  isPointMarkup,
  markupUpdated,
} from '../state/markup';
import {
  useSelectedLayers,
  setActiveBaseMap,
  setActiveOverlays,
 } from '../state/layers';
import {
  useFocused,
  useFocusedMarkup,
  useAddingMarkup,
  requestFocus,
  dismissAdding,
  updateMarkupDefaults,
  useInstructionsSnack,
  dismissInstructionsSnack,
  minimizeInstructionsSnack,
} from '../state/ui';
import { useNavigateUp, usePrevious, boundsForDesign, useEsc } from '../util';

const drawerWidth = 400;
const tabCount = 3;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  map: {
    height: 'calc(100vh - 64px)',
    flexGrow: 1,
    zIndex: 2,
  },
  drawer: {
    width: `${drawerWidth}px`,
    height: 'calc(100vh - 64px)',
    overflow: 'unset',
    background: 'white',
    borderRight: '1px solid rgba(0, 0, 0, 0.5)',
  },
  tabs: {
    backgroundColor: theme.palette.primary.main,
  },
  tab: {
    minWidth: `${drawerWidth/tabCount}px`,
    color: theme.palette.primary.contrastText,
  },
  tabPanel: {
    padding: '8px',
  },
  waiter: {
    position: 'absolute',
    width: '100vw',
    height: 'calc(100vh - 64px)',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  waiterProgress: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    margin: '-2.5em 0 0 -2.5em',
    color: 'white',
  },
  bottomNav: {
    borderTop: '1px solid rgba(0, 0, 0, 0.5)',
  },
  textItem: {
    padding: '1em',
    paddingTop: 0,
  },
  action: {
    transition: [
      theme.transitions.create('background-color', {
        duration: theme.transitions.duration.shortest,
      }),
      theme.transitions.create('color', {
        duration: theme.transitions.duration.shortest,
      }),
    ].join(', '),
  },
  actionSelected: {
    color: 'white !important',
    backgroundColor: theme.palette.primary.main,
  },
  actionSelectedWorking: {
    color: 'white !important',
    backgroundColor: '#888',
  },
  undo: {
    position: 'absolute',
    right: '1em',
    bottom: '1em',
    zIndex: theme.zIndex.drawer + 1,
  },
  snackChild: {
    whiteSpace: 'pre',
    transform: `translateX(${drawerWidth/2}px) !important`,
  },
  leftBottomButton: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1,
    left: `${drawerWidth + 16}px`,
    bottom: '16px',
    backgroundColor: theme.palette.primary.main,
  },
  undoSnack: {
    marginBottom: '56px',
  },
  noBackdrop: {
    zIndex: 2,
  },
  backdrop: {
    zIndex: 1,
  },
  cardSlider: {
    position: 'fixed',
    width: `${drawerWidth}px`,
    bottom: 0,
    left: 0,
    transform: 'translateY(100%)',
    opacity: 0,
    transition: [
      theme.transitions.create('transform', {
        duration: theme.transitions.duration.short,
      }),
      theme.transitions.create('opacity', {
        duration: theme.transitions.duration.short,
      }),
    ],
  },
  cardSliderUp: {
    transform: 'translateY(0)',
    opacity: 1,
  },
  editCardHolder: {
    padding: '1em',
  },
  card: {
    boxShadow: '0 0 20px black',
    maxHeight: '80vh',
    overflowY: 'auto',
  },
  instructions: {
    zIndex: theme.zIndex.drawer + 1,
    position: 'fixed',
    left: `calc(${drawerWidth}px + 1em)`,
    width: `calc(100vw - ${drawerWidth+56}px - 5em)`,
    bottom: '1em',
    padding: '1em',
    transform: 'translateY(calc(100% + 2em))',
    opacity: 0,
    transition: [
      theme.transitions.create('transform', {
        duration: theme.transitions.duration.short,
      }),
      theme.transitions.create('opacity', {
        duration: theme.transitions.duration.short,
      }),
    ],
  },
  instructionsShown: {
    transform: 'translateY(0)',
    opacity: 1,
  },
  instructionsButton: {
    marginTop: '-1em',
    marginBottom: '-1em',
    whiteSpace: 'nowrap',
    minWidth: 'unset',
  },
  instructionsClose: {
    marginTop: '-1em',
    marginBottom: '-1em',
  },
}));

const FullCard = ({open, design, markup, focusName, tracking, onClose, map}) => {
  const nameInput = useRef(null);
  const classes = useStyles();
  const prevID = usePrevious(markup?.properties?.id);

  useEffect(() => {
    if (open && (markup?.properties?.id !== prevID) && focusName && nameInput.current) {
      nameInput.current.focus();
    }
  }, [open, nameInput, markup?.properties?.id, focusName, prevID]);

  return (
    <div
        className={clsx(
          classes.noBackdrop,
          classes.cardSlider,
          open && classes.cardSliderUp,
        )}>
      <div className={classes.editCardHolder}>
        {
          design && markup &&
          <MarkupItemCard
              nameEditRef={nameInput}
              onClose={onClose}
              className={classes.card}
              design={design}
              markup={markup}
              tracking={tracking}
              map={map}/>
        }
      </div>
    </div>
  );
};

const MapDesignPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const routeParams = useParams();
  const did = routeParams.design;
  const isNew = did === 'new';
  const map = useRef(null);
  const mapHasMoved = useRef(false);
  const [mapInitialPos, setMapInitialPos] = useState(JSON.parse(window.localStorage.getItem('mapInitial') || 'null'));
  const storedLayers = useSelectedLayers();
  const [nameDialog, setNameDialog] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const upPath = useNavigateUp();
  const design = useDesign(did);
  const designs = useDesigns();
  const workingOnList = useWorkingOnDesigns();
  const adding = useAddingMarkup();
  const markupData = useMarkup(did);
  const [tab, setTab] = useState(0);
  const focused = useFocused();
  const focusedMarkup = useFocusedMarkup();
  const [lastFocusedMarkup, setLastFocusedMarkup] = useState(null);
  const instructions = useInstructionsSnack();
  const [preview, setPreview] = useState(false);
  const [importDialog, setImportDialog] = useState({open: false});
  const [shareEditDialog, setShareEditDialog] = useState(false);
  const lastFocused = usePrevious(focused?.markup);
  const [undo, setUndo] = useState(null);
  const [patternReferences, setPatternReferences] = useState({});
  const [initialized, setInitialized] = useState(false);
  const [initialHelp, setInitialHelp] = useState({show: false});
  const socket = useRef(null);
  const [trackingID, setTrackingID] = useState(null);
  const [trackingVersion, setTrackingVersion] = useState();
  const mounted = useRef(true);
  const [initState, setInitState] = useState(0);

  useEffect(() => {
    return () => mounted.current = false;
  }, []);

  useEffect(() => {
    if (design?.version && !trackingVersion) {
      setTrackingVersion(design.version);
    }
  }, [design?.version, trackingVersion]);

  useEffect(() => {
    if (did === 'new' || !trackingVersion) {
      return undefined;
    }
    let connect;
    connect = async () => {
      socket.current = await trackDesignChanges(did);
      if (!socket.current) {
        setTimeout(() => {
          if (mounted.current) {
            connect();
          }
        }, 1000);
        return;
      }
      if (!mounted.current) {
        socket.current.close();
        socket.current = null;
        return;
      }
      socket.current.onopen = (ev) => {
        socket.current.send(JSON.stringify({
          request: 'TRACKING',
          auth: authToken(),
          design: did,
          version: trackingVersion,
        }));
      };
      socket.current.onclose = (ev) => {
        socket.current = null;
        connect();
      };
      socket.current.onmessage = (ev) => {
        try {
          const json = JSON.parse(ev.data);
          switch (json.status) {
          case 'STARTED':
            setTrackingID(json.id);
            break;
          case 'CHANGED':
            dispatch(designUpdated(json.design));
            json.markup?.forEach((m) => dispatch(markupUpdated(json.design.id, {...m, design: did})));
            break;
          case 'DELETED':
            dispatch(designDeleted(did));
            break;
          default:
            console.warn('Unknown tracking message', json);
            break;
          }
        } catch(ex) {
          console.error('Error parsing JSON:', ex);
        }
      };
    };
    connect();
    return () => {
      if (socket.current) {
        socket.current.onopen = null;
        socket.current.onmessage = null;
        socket.current.onclose = null;
        socket.current.close();
        socket.current = null;
      }
    };
  }, [did, trackingVersion, dispatch]);

  useEffect(() => {
    setNameDialog(isNew);
  }, [isNew]);

  useEffect(() => {
    if (focusedMarkup) {
      setLastFocusedMarkup(focusedMarkup);
    } else if (adding.type) {
      setLastFocusedMarkup(adding.data);
    } else if (instructions?.show) {
      dispatch(dismissInstructionsSnack());
    }
  }, [focusedMarkup, adding, instructions?.show, dispatch]);

  useEffect(() => {
    if (!mapInitialPos) {
      navigator.geolocation.getCurrentPosition((pos) => {
        if (!mapHasMoved.current && pos?.coords) {
          const z = Math.max(Math.min(27 - Math.log2(Math.max(pos.coords.accuracy, 1)), 16), 5);
          if (map.current) {
            map.current.setView([pos.coords.latitude, pos.coords.longitude], z);
          }
          setMapInitialPos({
            lat: pos.coords.latitude,
            lon: pos.coords.longitude,
            z: z,
          });
        }
      }, null, {enableHighAccuracy: true});
    }
  }, [map, mapHasMoved, mapInitialPos]);

  useEffect(() => {
    switch (initState) {
    case 0:
      // First of all, if we don't have a designs list, get it
      if (!designs && !workingOnList) {
        dispatch(refreshDesigns());
      }
      setInitState(1);
      break;

    case 1:
      // Wait until we do have the list
      if (!workingOnList) {
        setInitState(2);
      }
      break;

    case 2:
      // Check if we want to redeem a token
      let started = false;
      (location.search ?? '').substring(1).split('&').forEach((q) => {
        const kv = q.split('=');
        if (kv.length === 2 && kv[0] === 'redeem') {
          // Yes; start the asynchronous redemption process and wait in state 3
          (async () => {
            const data = await redeemEditorToken(decodeURIComponent(kv[1]));
            if (data) {
              dispatch(designRedeemed(data));
              // Successful redemption; advance
              setInitState(3);
            } else {
              // Redemption failed; bail out
              setInitState(99);
            }
          })();
          started = true;
        }
      });
      setInitState(started ? 10 : 3);
      break;

    case 3:
      if (isNew) {
        // New design, we are ready
        setInitState(10);
      } else if (!design) {
        // No data, bail out
        setInitState(99);
      } else {
        // Otherwise, try to load design contents
        dispatch(refreshDesignContents(did, trackingID));
        setInitState(4);
      }
      break;

    case 4:
      // Wait for contents...
      if (!design) {
        // Meanwhile, design was deleted
        setInitState(99);
      } else if (design.contents) {
        setInitState(5);
      }
      break;

    case 5:
      // Running!
      if (!design) {
        // Meanwhile, design was deleted
        setInitState(99);
      } else if (!design.contents) {
        // Meanwhile, contents were invalidated
        setInitState(3);
      }
      break;

    case 10:
      // No-op state, wait for an async operation
      break;

    case 99:
      // Bail out
      setInitState(10);
      navigate(upPath, { replace: true });
      break;

    default:
      console.warn('Unknown init state', initState);
      break;
    }
  }, [initState, designs, design, did, workingOnList, navigate, location, upPath, dispatch, isNew, trackingID]);

  useEffect(() => {
    if (did && !isNew) {
      return () => dispatch(invalidateDesignContents(did));
    }
    return undefined;
  }, [did, isNew, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(requestFocus(null));
    };
  }, [dispatch]);

  const haveContents = !!design?.contents;
  useEffect(() => {
    if (haveContents && !initialized && map.current) {
      setInitialized(true);
      const b = boundsForDesign(design);
      if (b) {
        map.current.fitBounds(b);
      }
      if(Date.now() - Date.parse(design.created) < 15000) {
        const { background, area, point } = design.contents;
        setInitialHelp({
          show: true,
          type: (background?.length ?? 0) + (area?.length ?? 0) + (point?.length ?? 0) === 0 ? 'blank' : 'imported',
        });
      }
    }
  }, [haveContents, initialized, design]);

  useEffect(() => {
    if (map.current && focused?.flyTo && focused?.markup !== lastFocused && focusedMarkup) {
      const b = focusedMarkup.bbox;
      if (b) {
        map.current.flyToBounds([[b[1], b[0]], [b[3], b[2]]], {maxZoom: 18});
      }
    }
  }, [focused, lastFocused, focusedMarkup, map]);

  useEffect(() => {
    if (focusedMarkup) {
      if (isBackgroundMarkup(focusedMarkup) && tab !== 0) {
        setTab(0);
      }
      if (isAreaMarkup(focusedMarkup) && tab !== 1) {
        setTab(1);
      }
      if (isPointMarkup(focusedMarkup) && tab !== 2) {
        setTab(2);
      }
    }
  }, [focusedMarkup, tab]);

  const dragEnd = (type) => (result, provided) => {
    if (result.reason === 'DROP' && result?.destination) {
      const [droptype, dropid] = result.destination.droppableId.split('/');
      if (!dropid) {
        console.warn('Invalid droppable ID', result.destination.droppableId);
      } else {
        setUndo(null);
        dispatch(
          reorderMarkup(
            design.id,
            markupData,
            result.draggableId,
            dropid,
            result.destination.index,
            trackingID));
        if (droptype === type) {
          const f = dropid === 'root' ? null : parseInt(dropid);
          ['poi', 'line', 'area'].forEach(
            (t) => dispatch(updateMarkupDefaults(t, {folder: f})));
        }
      }
    }
  };

  useEsc(useCallback(() => {
    dispatch(dismissAdding());
    dispatch(requestFocus(null));
  }, [dispatch]));

  const performUndo = () => {
    if (Array.isArray(undo.undo)) {
      undo.undo.forEach((f) => dispatch(f));
    } else {
      dispatch(undo.undo);
    }
    setUndo({
      ...undo,
      undone: true,
      showRedoInfo: false,
      showUndoInfo: !!undo.name,
      undoInfo: t('undo-undone', {what: undo.name}),
    });
  };
  const performRedo = () => {
    if (Array.isArray(undo.redo)) {
      undo.redo.forEach((f) => dispatch(f));
    } else {
      dispatch(undo.redo);
    }
    setUndo({
      ...undo,
      undone: false,
      showUndoInfo: false,
      showRedoInfo: !!undo.name,
      showRedoAction: false,
      redoInfo: t('undo-redone', {what: undo.name}),
    });
  };

  return (
    <UndoContext.Provider value={[
      (name, redo, undo, dispatch, hint) => {
        setUndo({
          undo,
          redo,
          name,
          undone: false,
          redoInfo: hint,
          showRedoInfo: !!hint,
          showRedoAction: true,
        });
        if (dispatch) {
          if (Array.isArray(redo)) {
            redo.forEach((f) => dispatch(f));
          } else {
            dispatch(redo);
          }
        }
      },
      () => setUndo(null),
    ]}>
      <MapPatternContext.Provider value={patternReferences}>
        <AppPage className={classes.root} titleSuffix={design?.name}>
          <div className={classes.drawer}>
            {
              design &&
                <Box display='flex' flexDirection='column' height='100%'>
                  <DesignSidebarHeader
                      className={classes.noBackdrop}
                      design={design}
                      tracking={trackingID}
                      onPreview={() => setPreview(true)}
                      onImport={() => setImportDialog({source: 'phone', open: true})}
                      onImportGPX={() => setImportDialog({source: 'gpx', open: true})}
                      onShareEdit={() => setShareEditDialog(true)}/>
                  <Tabs
                      variant='fullWidth'
                      className={clsx(classes.tabs, classes.noBackdrop)}
                      textColor='secondary'
                      indicatorColor='secondary'
                      value={tab}
                      onChange={(ev, val) => {
                        dispatch(requestFocus(null));
                        dispatch(dismissAdding());
                        setTab(val);
                      }}>
                    <Tab className={classes.tab} label={t('tab-background')}/>
                    <Tab className={classes.tab} label={t('tab-areas')}/>
                    <Tab className={classes.tab} label={t('tab-points')}/>
                  </Tabs>
                  <Box flexGrow={1}>
                    <DrawerTabPanel container current={tab}>
                      <BackgroundMarkup
                          map={map.current}
                          design={design}
                          tracking={trackingID}
                          onDragEnd={dragEnd('BACKGROUND')} />
                      <AreaMarkup
                          map={map.current}
                          design={design}
                          tracking={trackingID}
                          onDragEnd={dragEnd('AREA')} />
                      <PointMarkup
                          map={map.current}
                          design={design}
                          tracking={trackingID}
                          onDragEnd={dragEnd('POINT')} />
                    </DrawerTabPanel>
                    <Backdrop
                        className={classes.backdrop} open={!!(focused?.markup || adding.type)}
                        onClick={() => {
                          dispatch(requestFocus(null));
                          dispatch(dismissAdding());
                        }}/>
                    <FullCard
                        open={!!(focused?.markup || adding.type)}
                        onClose={() => {
                          dispatch(requestFocus(null));
                          dispatch(dismissAdding());
                        }}
                        design={design}
                        markup={(adding.type ? adding.data : focusedMarkup) ?? lastFocusedMarkup}
                        focusName={!!(adding.type || focused?.focusName)}
                        tracking={trackingID}
                        map={map.current} />
                  </Box>
                </Box>
            }
          </div>
          <MapView
              forwardRef={map}
              layers={storedLayers}
              setActiveBaseMap={(id) => dispatch(setActiveBaseMap(id))}
              setActiveOverlays={(id) => dispatch(setActiveOverlays(id))}
              className={classes.map}
              center={mapInitialPos ? [mapInitialPos.lat, mapInitialPos.lon] : [57, 17]}
              zoom={mapInitialPos ? mapInitialPos.z : 5}
              editable={true}
              editOptions={mapEditOptions}
              whenCreated={(m) => {
                m.on('movestart', () => mapHasMoved.current = true);
                m.on('zoomstart', () => mapHasMoved.current = true);
                const updateCenter = () => {
                  const c = m.getCenter();
                  const z = m.getZoom();
                  if (c && z) {
                    window.localStorage.setItem('mapInitial', JSON.stringify({
                      lat: c.lat,
                      lon: c.lng,
                      z: z,
                    }));
                  }
                };
                m.on('moveend', updateCenter);
                m.on('zoomend', updateCenter);
                setPatternReferences(registerMapPatterns(m));
              }}>
            <MapEditor design={design} tracking={trackingID}/>
          </MapView>
          <Card
              elevation={8}
              className={clsx(
                classes.instructions,
                (instructions?.show && !instructions?.minimized) && classes.instructionsShown,
              )}>
            <Box display='flex' flexDirection='row'>
              <Box flexGrow={1}>
                {instructions?.text ?? ''}
              </Box>
              {
                instructions?.doneToUnfocus &&
                  <Button
                      className={classes.instructionsButton}
                      color='secondary'
                      onClick={() => dispatch(requestFocus(null))}>
                    <Trans>done</Trans>
                  </Button>
              }
              {
                instructions?.setAddingButton &&
                  <Button
                      className={classes.instructionsButton}
                      color='secondary'
                      onClick={() => dispatch(instructions.setAddingButton.adding)}>
                    {instructions.setAddingButton.title}
                  </Button>
              }
              <IconButton
                  className={classes.instructionsClose}
                  onClick={() => {
                    dispatch(instructions?.doneToUnfocus ?
                             minimizeInstructionsSnack() :
                             dismissInstructionsSnack());
                    if (instructions?.stopAddingOnClose) {
                      dispatch(dismissAdding());
                    }
                  }}>
                <CloseIcon/>
              </IconButton>
            </Box>
          </Card>
          <Zoom in={!undo || !undo.undone}>
            <div className={classes.undo}>
              <Fab color='secondary' onClick={() => performUndo()} disabled={!undo}>
                <UndoIcon/>
              </Fab>
            </div>
          </Zoom>
          <Zoom in={!!undo?.undone}>
            <div className={classes.undo}>
              <Fab color='secondary' onClick={() => performRedo()}>
                <RedoIcon/>
              </Fab>
            </div>
          </Zoom>
          <Snackbar
              className={classes.undoSnack}
              anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom',
              }}
              open={undo?.showUndoInfo || false}
              autoHideDuration={3000}
              onClose={() => setUndo({...undo, showUndoInfo: false})}>
            <Alert variant='filled' severity='success'>
              {undo?.undoInfo}
            </Alert>
          </Snackbar>
          <Snackbar
              className={classes.undoSnack}
              anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom',
              }}
              open={undo?.showRedoInfo || false}
              autoHideDuration={3000}
              onClose={() => setUndo({...undo, showRedoInfo: false})}>
            <Alert
                variant='filled'
                severity='success'
                action={!undo?.showRedoAction ? null : (
                  <Button color='inherit' onClick={() => performUndo()}>
                    <Trans>undo</Trans>
                  </Button>
                )}>
              {undo?.redoInfo}
            </Alert>
          </Snackbar>
          {
            <Fade in={instructions?.show && instructions.minimized}>
              <div className={classes.leftBottomButton}>
                <Button
                    color='secondary'
                    onClick={() => dispatch(requestFocus(null))}>
                  <Trans>done</Trans>
                </Button>
              </div>
            </Fade>
          }
          <NewDesignNameDialog open={nameDialog} upPath={upPath}/>
          {
            !isNew && (workingOnList || !design?.contents) &&
              <div className={classes.waiter}>
                <CircularProgress className={classes.waiterProgress} size='5em'/>
              </div>
          }
        </AppPage>
        <Dialog
            maxWidth='xl'
            open={preview}
            onClose={() => setPreview(false)}>
          <DialogTitle>
            <Trans>map-preview</Trans>
          </DialogTitle>
          <DialogContent>
            <Preview design={design} initialLayers={storedLayers}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setPreview(false)}>
              <Trans>close</Trans>
            </Button>
          </DialogActions>
        </Dialog>
        <ImportDialog
            map={map.current}
            design={design}
            tracking={trackingID}
            open={importDialog.open}
            source={importDialog.source}
            onClose={() => setImportDialog({...importDialog, open: false})}/>
        <ShareEditDialog
            design={design}
            open={shareEditDialog}
            onClose={() => setShareEditDialog(false)}/>
        <Dialog open={initialHelp.show} onClose={() => setInitialHelp({...initialHelp, show: false})}>
          <DialogTitle>
            {t('initial-help-title', {name: design?.name ?? ''})}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('initial-help-'+(initialHelp.type ?? 'blank'))}
            </DialogContentText>
            <DialogContentText>
              <Trans>initial-help-common</Trans>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setInitialHelp({...initialHelp, show: false})}>
              <Trans>close</Trans>
            </Button>
          </DialogActions>
        </Dialog>
      </MapPatternContext.Provider>
    </UndoContext.Provider>
  );
};

export default MapDesignPage;
