// -*- mode: RJSX; js-indent-level: 2; -*-

import { createContext, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { addTitleSuffix, removeTitleSuffix } from '../state/ui';

const useStyles = makeStyles(() => ({
  page: {
    position: 'absolute',
    top: 0,
    width: '100vw',
  },
  padding: {
    paddingTop: '64px',
  },
}));

export const HaveMenuContext = createContext();

const AppPage = ({noPadding, menu, titleSuffix, className, ...props}) => {
  const menuButtonCtx = useContext(HaveMenuContext);
  const classes = useStyles();
  const { children, ...divProps } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (menu) {
      menuButtonCtx.fn(1);
      return () => menuButtonCtx.fn(-1);
    }
    return undefined;
  }, [menu, menuButtonCtx]);

  useEffect(() => {
    if (titleSuffix) {
      dispatch(addTitleSuffix(titleSuffix));
      return () => dispatch(removeTitleSuffix(titleSuffix));
    }
    return undefined;
  }, [titleSuffix, dispatch]);

  return (
    <div className={clsx(classes.page, !noPadding && classes.padding, className)} {...divProps}>
      {children}
    </div>
  );
};

export default AppPage;
